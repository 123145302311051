import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Link, useLocation } from "react-router-dom";

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import Login from "./components/Login";
import Register from "./components/Register";
// import Home from "./components/Home";
import Profile from "./components/Profile";
import BoardUser from "./components/BoardUser";
import BoardAgent from "./components/BoardAgent";
import BoardAdmin from "./components/BoardAdmin";
import BoardAdminPagination from "./components/BoardAdminPagination";
// import ReportAdmin from "./components/ReportAdmin";
import Statement from "./components/Statement";
import RegistrationPage from "./components/RegistrationPage";
import RegistrationSuccess from "./components/RegistrationSuccess";
import GameBoard from "./components/GameBoard";
import GameBoard2 from "./components/GameBoard2";
import BoardAdmin_Nav from "./components/BoardAdmin_Nav";
import ChangePassword from "./components/ChangePassword";
import Unsettled from "./components/Unsettled";
import AddDeleteUpi from "./components/AddDeleteUpi";
import Statement2 from "./components/Statement2";

import { logout, fetchState, getBalance } from "./actions/auth";
import { clearMessage } from "./actions/message";
import RechargeIcon from "./components/RechargeIcon";
import WithdrawIcon from "./components/WithdrawIcon";
import RechargeApproval from "./components/RechargeApproval";
import RechargeApprove from "./components/RechargeApprove";
import WithdrawApproval from "./components/WithdrawApproval";
import WithdrawApprove from "./components/WithdrawApprove";
import TokenService from "./services/token-service";
import { httpHelpers } from "./services/httpHelpers"
import { Navigate, useNavigate  } from 'react-router-dom';
import AgentRegistrationPage from "./components/AgentRegistrationPage";

const App = () => {
  console.log(window.location.href);
  const href = window.location.href;
  let role = "agent";
  if (href.includes("admin")) {
    role = "admin";
  }

  let register = false;
  if (href.includes("registerUser")) {
    register=true;
  }


  console.log("app role", role);

  const [showAgentBoard, setShowAgentBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [balance, setBalance] = useState();

  const initialState = {
    "email":'',
    "password":''
  }

  const { user: currentUser} = useSelector((state) => state.auth);
  if (currentUser) {
    console.log("info", {currentUser}, balance)
    // setUserProfile(currentUser);
  } 
  // else if (href.includes("admin")) {
  //   let tempUser = TokenService.getUser("admin");
  //   if (tempUser) {
  //     setUserProfile(tempUser);
  //   }
  // } else if (href.includes("agent")) {
  //   let tempUser = TokenService.getUser("agent");
  //   if (tempUser) {
  //     setUserProfile(tempUser);
  //   }
  // }
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  let url = "alpha/getBalance";
  if (role === "agent")
    url = "beta/getBalance";
  const api = httpHelpers()

//   useEffect(() => {
//     if (["/logadmin", "/logagent", "/regadmin", "/regagent", "/profadmin",
//   "/profagent", "/boardadmin", "/boardagent", "/statementadmin", "/statementagent",
// "/gameboardadmin", "/cpadmin", "cpagent", "/unsettledadmin", "/unsettledagent", "/approvalagent"].includes(location.pathname)) {
//       dispatch(clearMessage()); // clear message when changing location
//     }
//   }, [dispatch, location]);

  const logOut = useCallback(() => {
    // dispatch(logout("", role));
    if (role === "admin") {
      navigate("/logadmin");
    } else if (role === "agent") {
      navigate("/logagent");
    }
  }, [dispatch]);

  // let timeLeft = 5;
  // const Ref = useRef(null);

  // const getTimeRemaining = (e) => {
  //   timeLeft = timeLeft - 1;
  //   const seconds = timeLeft;
  //   return seconds;
  // }

  // const startTimer = (e) => {
  //   let seconds = getTimeRemaining(e);
  //   if (role === "admin" && window.adminBalance) {
  //     console.log("app seconds", seconds, window.adminBalance);
  //   } else if (role === "agent" && window.agentBalance) {
  //     console.log("app seconds", seconds, window.agentBalance);
  //   }
    
  //   if (seconds >= 0) {
  //     if (role) {
  //       let user = TokenService.getUser(role);
  //       if (role === "admin") {
  //         if (window.adminBalance) {
  //           user.balance = window.adminBalance;
  //         }
  //       } else if (role === "agent") {
  //         if (window.agentBalance) {
  //           user.balance = window.agentBalance;
  //         }
  //       }
        
  //       TokenService.setUser(user, role);
  //       if (user && user.balance) {
  //         setBalance(user.balance);
  //       }
  //     } else if (currentUser) {
  //       setBalance(currentUser.balance);
  //     }

  //     // updateBalance(window.myBalance);
  //     if (Ref.current) {
  //       clearInterval(Ref.current);
  //     }
  //     timeLeft = 5;
  //   }
  // }

  // useEffect(() => {
  //   dispatch(fetchState());
  //   const id = setInterval (() => {
  //     startTimer(seconds => seconds + 5);
  //   }, 10000)
  //   return () => clearInterval(id);
  // }, []);

  useEffect(() => {
    console.log('cus', currentUser)
    const user = TokenService.getUser(role);
    console.log("cus 2", user);
    if (user && user.accountStatus.includes("ACTIVE")) {
      // setShowAdminBoard(currentUser.user.role.includes("admin"));
      if (role === "admin") {
        setShowAdminBoard(true);
        setShowAgentBoard(false);
      } else if (role === "agent") {
        setShowAdminBoard(false);
        setShowAgentBoard(true);
      }

      updateBalance(user.balance);
    } else {
      console.log("JWT is null, not authorize!");
      setShowAgentBoard(false);
      setShowAdminBoard(false);
    }
  }, [currentUser]);

  const updateBalance = (updatedBalance) => {
    setBalance(updatedBalance);
  }

  const fetchBalance = () => {
		api
			.get(`${url}`)
			.then(res => {
				console.log("fetch balance res", res.data)
        let role = "agent";
        if (href.includes("admin")) {
          role = "admin";
        }
        console.log("fetch balance res", role)
        let temp = TokenService.getUser(role);
        temp.balance = res.data;
        TokenService.setUser(temp, role);
				updateBalance(res.data);
			})
			.catch(err => {
				console.log(err.response.status)
				if (err.response.status === 401) {
          TokenService.removeUser(role);
          logOut();
          window.location.reload();
					// logOut();
				}
			})
	}

  const logout = () => {
    TokenService.removeUser(role);
    logOut();
    window.location.reload();
  }

  // useEffect(() => {

  // }, [balance]);

  console.log(currentUser)
  console.log(showAgentBoard)
  console.log(showAdminBoard)

  const NavBar = () => {
    return (
      <>
      <nav className="navbar navbar-expand navbar-light" style={{backgroundColor: "#E6E6FA", marginRight: "30px"}}>
        {/* <Link className="navbar-brand">
          <h4 style={{marginLeft: "10px"}}>Login - Board</h4>
        </Link> */}
        <div>
          <label className="navbar-brand" style={{marginLeft: "10px", paddingBottom: "12px", verticalAlign: "center"}}></label>
        </div>
        <div className="navbar-nav ml-auto">
          {/* <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li> */}

          {showAgentBoard && (
            <li className="nav-item">
              <Link to="/boardagent" className="nav-link">
                My Users
              </Link>
            </li>
          )}

          {/* {showAgentBoard && (
            <li className="nav-item">
              <Link to={"/boardagentnav"} className="nav-link">
                Agent Board
              </Link>
            </li>
          )} */}

          {showAgentBoard && (
            <li className="nav-item">
              <Link to="/regagent" className="nav-link">
                Add User
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to="/boardadmin" className="nav-link">
                My Agents
              </Link>
            </li>
          )}

          {/* {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/reportadmin"} className="nav-link">
                Statement
              </Link>
            </li>
          )}

          {showAgentBoard && (
            <li className="nav-item">
              <Link to={"/reportagent"} className="nav-link">
                Statement
              </Link>
            </li>
          )} */}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to="/statementadmin" className="nav-link">
                Statement
              </Link>
            </li>
          )}

          {showAgentBoard && (
            <li className="nav-item">
              <Link to="/statementagent" className="nav-link">
                Statement
              </Link>
            </li>
          )}

          {/* {showAdminBoard && (
            <li className="nav-item">
              <Link to="/unsettledadmin" className="nav-link">
                Unsettled
              </Link>
            </li>
          )} */}

          {/* {showAgentBoard && (
            <li className="nav-item">
              <Link to="/unsettledagent" className="nav-link">
                Unsettled
              </Link>
            </li>
          )} */}
          {showAgentBoard && (
            <li className="nav-item">
              <Link to="/myupiagent" className="nav-link">
                My UPI
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to="/gameboardadmin" className="nav-link">
                Game Board
              </Link>
            </li>
          )}

          {/* {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/boardadminnav"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )} */}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to="/regadmin" className="nav-link">
                Add Agent
              </Link>
            </li>
          )}

          {showAgentBoard && (
              <li className="nav-item">
                <Link to="/withdrawagent" className="nav-link">
                  <WithdrawIcon />
                </Link>
              </li>
            )}

          {/* {currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )} */}
        </div>
        {showAdminBoard || showAgentBoard ? (
            <div className="navbar-nav ml-auto" style={{marginLeft: "auto"}}>
              <li className="nav-item">
                <Link to="#" className="nav-link" onClick={() => fetchBalance()}>
                  {/* {currentUser ? "Balance: " + (currentUser.balance).toFixed(1) : ""} */}
                  {balance ? "Balance: " + (balance).toFixed(1) : ""}
                </Link>
              </li>
              {showAdminBoard && (
              <li className="nav-item">
                <Link to="/cpadmin" className="nav-link">
                  Change Password
                </Link>
              </li>
            )}
            {showAgentBoard && (
              <li className="nav-item">
                <Link to="/rechargeagent" className="nav-link">
                  <RechargeIcon />
                </Link>
              </li>
            )}
            {showAgentBoard && (
              <li className="nav-item">
                <Link to="/cpagent" className="nav-link">
                  Change Password
                </Link>
              </li>
            )}
            <li className="nav-item">
              <Link to={role === "admin" ? "/profadmin" : "/profagent"} className="nav-link">
                {currentUser ? currentUser.userName : ""}
              </Link>
            </li>
            <li className="nav-item">
              <Link to={role === "admin" ? "/logadmin" : "/logagent"} className="nav-link" onClick={logout}>
                Log Out
              </Link>
            </li>
          </div>
        ) : !register && (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              
              <Link to={role === "admin" ? "./logadmin" : "./logagent"} className="nav-link">
                Login
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                Sign Up
              </Link>
            </li> */}
          </div>
        )}
      </nav>
      </>
    )
  }

  return (
    <div className="App">
        <NavBar />
        <div className="top-div">
          <Routes>
            {/* <Route path="/" exact element={<Home />} /> */}
            {/* <Route path="/" exact element={<Login />} /> */}
            {role === "admin" ? <Route path="/logadmin" exact element={<Login />} /> :
            <Route path="/logagent" exact element={<Login />} />}

            {/* <Route path="/home" element={<Home />} /> */}
            {/* <Route path="/login" element={<Login />} /> */}
            {role === "admin" ? <Route path="/regadmin" element={<Register />} /> :
            <Route path="/regagent" element={<Register />} />}

            {role === "admin" ? <Route path="/profadmin" element={<Profile />} /> :
            <Route path="/profagent" element={<Profile />} />}
            {/* <Route path="/user" element={<BoardUser />} /> */}

            {role === "admin" ? <Route path="/boardadmin" element={<BoardAdminPagination />} /> :
            <Route path="/boardagent" element={<BoardAdminPagination />} />}

            { <Route path="/registerUser" element={<RegistrationPage />} />}
            { <Route path="/register" element={<AgentRegistrationPage />} />}


            { <Route path="/registrationSuccess" element={<RegistrationSuccess />} />}


            {role === "admin" ? <Route path="/statementadmin" element={<Statement2 />} /> :
            <Route path="/statementagent" element={<Statement2 />} />}

            

            {role === "admin" && <Route path="/gameboardadmin" element={<GameBoard2 />} />}

            {/* {role === "admin" ? <Route path="/boardadminnav" element={<BoardAdmin_Nav />} /> :
            <Route path="/boardagentnav" element={<BoardAdmin_Nav />} />} */}

            {role === "admin" ? <Route path="/cpadmin" element={<ChangePassword />} /> :
            <Route path="/cpagent" element={<ChangePassword />} />}

            {/* {role === "admin" ? <Route path="/unsettledadmin" element={<Unsettled />} /> :
            <Route path="/unsettledagent" element={<Unsettled />} />} */}

            {role === "agent" && <Route path="/myupiagent" element={<AddDeleteUpi />} />}

            {/* {role === "agent" && <Route path="/rechargeagent" element={<RechargeApproval />} />} */}
            {role === "agent" && <Route path="/rechargeagent" element={<RechargeApprove />} />}

            {/* {role === "agent" && <Route path="/withdrawagent" element={<WithdrawApproval />} />} */}
            {role === "agent" && <Route path="/withdrawagent" element={<WithdrawApprove />} />}

          </Routes>
        </div>
    </div>
  );
}

export default App;
