import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../services/token-service";
import TextField from "@material-ui/core/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { notifyAllUsers, notifyUser, logout } from "../actions/auth";

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  
  const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className="alert alert-danger" role="alert">
          The password must be between 6 and 40 characters.
        </div>
      );
    }
  };
  
  const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid phone.
        </div>
      );
    }
  };

  const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter in valid range (0 - 100).
        </div>
      );
    }
  };

//   const Checkbox = ({ label, value, onChange }) => {
//   return (
//     <label>
//       <input type="checkbox" checked={value} onChange={onChange} />
//       {label}
//     </label>
//   );
// };

export default function NotifyUser({user, index, hidePopup, type, fromName}) {
    // console.log("Test", user)
    const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const form = useRef();
  const checkBtn = useRef();

  const [toName, setToName] = useState("");
  const [fromMessage, setFromMessage] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [load, setLoadData] = useState(true);
  const [checked, setChecked] = useState(false);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (load) {
    if (type === "USER") {
      setToName(user.userName);
    }
    setLoadData(false);
  }

  const onChangeFromMessage = (e) => {
    const fromMsg = e.target.value;
    setFromMessage(fromMsg);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleChange = (event) => {
    const checkVal = event.target.checked;
    // console.log("checkVal", checkVal);
    setChecked(checkVal);
  };

  const handleNotifyUser = (e) => {
    console.log("handleNotifyUser", type);
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      if (type === "ALL") {
        dispatch(notifyAllUsers(toName, fromMessage, password, checked, role))
            .then((data) => {
            console.log("notify all users data: ", data);
            if (data.status === 401) {
                if (data.data === "Wrong password") {
                    setSuccessful(false);
                } else {
                  TokenService.removeUser(role);
                  logOut();
                  window.location.reload();
                    // logOut(role);
                }
            } else if (data.status === 200) {
                setSuccessful(true);
                // fetchUpdatedRecords();
                // window.location.reload();
            } else {
              setSuccessful(false);
            }
            })
            .catch(() => {
            setSuccessful(false);
            });
        } else {
            dispatch(notifyUser(fromName, toName, fromMessage, password, checked, role))
            .then((data) => {
            console.log("notify user data: ", data);
            if (data.status === 401) {
                if (data.data === "Wrong password") {
                    setSuccessful(false);
                } else {
                  TokenService.removeUser(role);
                  logOut();
                  window.location.reload();
                    // logOut(role);
                }
            } else if (data.status === 200) {
                setSuccessful(true);
                // window.location.reload();
            } else {
              setSuccessful(false);
            }
            })
            .catch(() => {
            setSuccessful(false);
            });
        }
    }
  };

  const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

    return (
        <div className="portal-backdrop">
        <div className="portal">
        <div>
            <button className="board-buttons board-buttons-nav-bar-dark-smallest right" onClick={() => type === "ALL" ? hidePopup() : hidePopup(user.userName, index)}>X</button>
        </div>
        <div>
        <Form onSubmit={handleNotifyUser} ref={form}>
          {!successful && (
            <div>
              {type === "USER" && (<div className="form-group">
                <label className="label" htmlFor="username">{role === "admin" ? "To Agent ID" : "To User ID"}</label>
                <TextField
                  value={toName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="toName"
                  name="toName"
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>)}
              <div className="form-group">
                <label className="label" htmlFor="username">{role === "admin" ? "From Admin ID" : "From Agent ID"}</label>
                <TextField
                  value={fromName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="fromName"
                  name="fromName"
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="form-group">
                <label className="label" htmlFor="fromMessage">Message</label>
                <TextField
                  value={fromMessage}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="fromMessage"
                  onChange={onChangeFromMessage}
                  name="fromMessage"
                  autoFocus
                  autoComplete="off"
                />
              </div>
              <div className="form-group">
                {/* <Checkbox
                    label="My Value"
                    value={checked}
                    onChange={handleChange}
                  /> */}
                  <FormControlLabel 
                    control={<Checkbox
                      checked={checked}
                      onChange={handleChange}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                    />} 
                    label="Send notification via e-mail also"
                  />
              </div>
              <div className="form-group">
                <label className="label" htmlFor="password">Enter Your Password</label>
                <TextField
                  value={password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  onChange={onChangePassword}
                  name="password"
                  autoComplete="off"
                  type="password"
                />
              </div>
              <div className="form-group" style={{marginTop: "30px"}}>
                <button className="board-buttons board-buttons-nav-bar-dark">{type === "ALL" ? "Notify All Users" : "Notify User"}</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        </div>

      {successful && (<div className="form-group">
        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
          {type === "ALL" ? "Notified All Users" : "Notified User"} Successfully!
        </div>
      </div>)}
      </div>
    </div>
    )
}