import CrudUser from "./CrudUser"
import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LOGOUT } from "../actions/types";
import { clearMessage, setMessage } from "../actions/message";
import TokenService from "../services/token-service";
import { httpHelpers } from "../services/httpHelpers";
import Popup from './Popup';
import Register from "./Register";
import Test from "./Test";
import DepositWithdraw from "./DepositWithdraw";
import Block from "./Block";
import "../styles/Adag.css"
import PrevImage from '../assets/prev_img.png';
import NextImage from '../assets/next_img.png';
import Play from '../assets/play.jpg';
import UserBetTransactionTree from "./UserBetTransactionTree";
import NotifyUser from "./NotifyUser";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

const BoardAdminPagination = () => {
  const href = window.location.href;
  let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}

  const { message } = useSelector(state => state.message);
  const [showError, setShowError] = useState(true);
  const [successful, setSuccessful] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [myUserName, setMyUserName] = useState(TokenService.getUserName(role));
  const [showNotifyAllComponent, setShowNotifyAllComponent] = useState(false);

  const togglePopup = (user) => {
	setIsOpen(!isOpen);
	if (!isOpen) {
		setSelectedUser(user)
	}
  }

  useEffect(() => {
    if (!showError) {
			dispatch(clearMessage());
		}
		setShowError(false);
  }, []);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logOut = useCallback(() => {
    if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

  // console.log("message 1", message);
	if (message && message === LOGOUT) {
		console.log("message 3", message);
		TokenService.removeUser(role);
		logOut();
		window.location.reload();
	}

  // const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [startRecord, setStartRecord] = useState(1);
  const [endRecord, setEndRecord] = useState(1);
  const api = httpHelpers();
  const [users, setUsers] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [buttons, setButtons] = useState([]);
//   const buttons = [
// 	<Button key="one" onClick={() => setCurrentPage(5)}>One</Button>,
// 	<Button key="two" onClick={() => setCurrentPage(10)}>Two</Button>,
// 	<Button key="three" onClick={() => setCurrentPage(15)}>Three</Button>,
//   ];

	const handlePageNumberButtons = (event) => {
		// console.log("handlePageNumberButtons", event.target.value);
		const pN = event.target.value;
		setCurrentPage(parseInt(pN));
  	};

  const handleChange = (event) => {
    // setItemsPerPage(event.target.value);
	setLimit(event.target.value);
  };

  useEffect(() => {
	fetchCurrentRecords();
  }, [offset, limit]);

  const fetchCurrentRecords = () => {
	var url = `alpha/getAgents?offset=${offset}&limit=${limit}`;
	if (role === "agent") {
		url = `beta/getUsers?offset=${offset}&limit=${limit}`
	}
	api
		.get(`${url}`)
      	.then(res => {
			console.log("report res", res.data)
			if (role === "admin") {
				setUsers(res.data);
			} else {
				console.log("report res users", res.data.users);
				setUsers(res.data.users);
				setTotalItems(res.data.count);
				setTotalPages(Math.ceil(res.data.count / limit));
				setStartRecord(offset + 1);
				setEndRecord(offset + limit);

				var tempButtons = [];
				const tPages = Math.ceil(res.data.count / limit);
				for (var i = 0; i < tPages; i++) {
					var bkey = "key" + i;
					tempButtons[i] = <Button sx={{ fontSize: 25, bgcolor: "white", color: 'black' }} key={bkey} value={i} onClick={(event) => handlePageNumberButtons(event)}>{i+1}</Button>;
				};

				setButtons(tempButtons);
			}
		})
		// .then((response) => response.data.users)
      	// .then((allUsers) => setUsers(allUsers))
		.catch(err => {
			console.log(err.response.status)
			if (err.response.status === 401) {
				TokenService.removeUser(role);
				logOut();
				window.location.reload();
				// logOut(role);
			} else {
        		setSuccessful(false);
			}
	});
  }

  function hideNotifyAllComponent() {
	setShowNotifyAllComponent(false);
  }

  function decrement() {
    setCurrentPage(prev => prev - 1);
  }

  function increment() {
    setCurrentPage(prev => prev + 1);
  }

  useEffect(() => {
	console.log("currentPage", currentPage);
	setButtons([]);
    setOffset(limit * currentPage);
  }, [currentPage]);

  const showUpdateAgent = (userName) => {
	const form = document.getElementsByClassName(`show-form-${userName}`)
    form[0].classList.toggle("hide-form")
  }

  const showBlock = (userName, index) => {
    const form = document.getElementsByClassName(`show-form-${index}Block`)
    form[0].classList.toggle("hide-form")
  }

  const showDeposit = (userName, index) => {
    const form = document.getElementsByClassName(`show-form-${index}D`)
    form[0].classList.toggle("hide-form")
  }

  const showWithdraw = (userName, index) => {
  	const form = document.getElementsByClassName(`show-form-${index}W`)
	form[0].classList.toggle("hide-form")
  }

  const showNotifyUser = (userName, index) => {
    const form = document.getElementsByClassName(`show-form-${index}NU`)
    form[0].classList.toggle("hide-form")
  }

  const showUserBetTransactionTree = (userName, index) => {
    const form = document.getElementsByClassName(`show-form-${index}BTT`)
    form[0].classList.toggle("hide-form")
  }

  const Row = ({ user, index }) => {
		return (
			<>	<div className='myusers-row'>
					<div style={{paddingTop: "10px"}}>{user.name}</div>
					{role==="agent" && (<div style={{paddingTop: "6px", width: "3%"}}>
						<button className="board-buttons board-buttons-nav-bar-dark-tiny-trans"
							onClick={() => showUserBetTransactionTree(user, index)}>
							<img src={Play} className="play-img" />
						</button>
					</div>)}
					<div style={{paddingTop: "10px"}}>{user.userName}</div>
					{role==="agent" && (<div style={{paddingTop: "10px"}}>{user.id}</div>)}
					{role==="agent" && (<div style={{paddingTop: "10px"}}>{user.phoneNumber}</div>)}
					{role==="admin" && (<div style={{paddingTop: "10px", width: "13%"}}>{user.phoneNumber}</div>)}
					{role==="agent" && (<div style={{paddingTop: "10px"}}>{user.firstLevelCount}</div>)}
					<div style={{paddingTop: "10px"}}>{(user.balance).toFixed(1)}</div>
					{role==="admin" ? <div style={{paddingTop: "10px", width: "13%"}}>{user.adminCommission}</div> : ""}
					{role==="admin" && (<div style={{display: "flex", width: "22%", marginLeft: "30px"}}>
						<button className="board-buttons board-buttons-nav-bar-dark"
							onClick={() => showUpdateAgent(user.userName)}>
								Edit
						</button>
						<button 
							className={user.accountStatus==="BLOCKED" ? "board-buttons board-buttons-nav-bar-dark-red" : "board-buttons board-buttons-nav-bar-dark"}
							onClick={() => showBlock(user, index)} >
								{user.accountStatus==="BLOCKED" ? "Unblock" : "Block"}
						</button>
						<button 
							className="board-buttons board-buttons-nav-bar-dark-smallest"
							onClick={() => showWithdraw(user, index)} >
								W
						</button>
						<button 
							className="board-buttons board-buttons-nav-bar-dark-smallest"
							onClick={() => showDeposit(user, index)} >
								D
						</button>
					</div>)}
					{role==="agent" && (<div style={{display: "flex", width: "20%", marginLeft: "20px"}}>
						<button className="board-buttons board-buttons-nav-bar-dark"
							style={{width: "30%"}}
							onClick={() => showUpdateAgent(user.userName)}>
							Edit
						</button>
						<button className={user.accountStatus==="BLOCKED" ? "board-buttons board-buttons-nav-bar-dark-red" : "board-buttons board-buttons-nav-bar-dark"}
							style={{width: "30%"}}
							onClick={() => showBlock(user, index)} >
							{user.accountStatus==="BLOCKED" ? "Unblock" : "Block"}
						</button>
						<button className="board-buttons board-buttons-nav-bar-dark-smallest"
							onClick={() => showWithdraw(user, index)} >
							W
						</button>
						<button className="board-buttons board-buttons-nav-bar-dark-smallest"
							onClick={() => showDeposit(user, index)} >
							D
						</button>
						<button className="board-buttons board-buttons-nav-bar-dark-smallest"
							style={{width: "35%"}}
							onClick={() => showNotifyUser(user, index)} >
							Notify
						</button>
					</div>)}
				</div>
				<div className={`hide-form show-form-${index}BTT`}>
					<div>
						<UserBetTransactionTree user={user} index={index} 
							hidePopup={showUserBetTransactionTree} logout={logOut} />
					</div>
        		</div>
				<div className={`hide-form show-form-${user.userName}`}>
					<div>
						<Test user={user} hidePopup={showUpdateAgent} 
							fetchUpdatedRecords={fetchCurrentRecords}/>
					</div>
        		</div>
				<div className={`hide-form show-form-${index}W`}>
					<div>
						<DepositWithdraw user={user} index={index} 
							hidePopup={showWithdraw} type={"W"} fetchUpdatedRecords={fetchCurrentRecords}/>
					</div>
        		</div>
				<div className={`hide-form show-form-${index}D`}>
					<div>
						<DepositWithdraw user={user} index={index} 
							hidePopup={showDeposit} type={"D"} fetchUpdatedRecords={fetchCurrentRecords}/>
					</div>
        		</div>
				<div className={`hide-form show-form-${index}Block`}>
					<div>
						<Block user={user} index={index} 
							showBlock={showBlock} logout={logOut} fetchUpdatedRecords={fetchCurrentRecords}/>
					</div>
        		</div>
				<div className={`hide-form show-form-${index}NU`}>
					<div>
						<NotifyUser user={user} index={index} 
							hidePopup={showNotifyUser} type={"USER"} fromName={myUserName} />
					</div>
        		</div>
			</>
		)
	}

  return (
    <>
		<main>
			<div>
			<FormControl sx={{ m: 1, minWidth: 120, bgcolor: "white", float: "left" }} size="large">
				<InputLabel id="demo-simple-select-label">Items / Page</InputLabel>
				<Select
					sx={{ fontSize: 25, minWidth: 120 }}
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={limit}
					label="Items / Page"
					onChange={handleChange}
					>
					<MenuItem value={10}>10</MenuItem>
					<MenuItem value={20}>20</MenuItem>
					<MenuItem value={50}>50</MenuItem>
					<MenuItem value={100}>100</MenuItem>
				</Select>
			</FormControl>
			{showNotifyAllComponent && (<NotifyUser type={"ALL"} fromName={myUserName} hidePopup={hideNotifyAllComponent} />)}
			{message && (
				<div className="form-group">
					<div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
					{message}
					</div>
				</div>
				)}
			{role === "agent" && (<div>
				<button onClick={() => setShowNotifyAllComponent(true)} className="board-buttons board-buttons-nav-bar-dark"
					style={{height: "50px", width: "100px", float: "right", marginBottom: "20px",
						fonstSize: "25px"}}>
					Notify All
				</button>
			</div>)}
			<div className='myusers-titles'>
				<div>Name</div>
					{role==="agent" && (<div style={{width: "3%"}}></div>)}
					{role==="agent" && (<div style={{width: "13%"}}>User Name</div>)}
					{role==="admin" && (<div>User Name</div>)}
					{role==="agent" && (<div>User ID</div>)}
					{/* <div>Password</div> */}
					{role==="agent" && (<div>Phone</div>)}
					{role==="admin" && (<div style={{width: "13%"}}>Phone</div>)}
					{role==="agent" && (<div style={{width: "15%"}}>First Level Members</div>)}
					{role==="admin" && (<div>Balance</div>)}
					{role==="agent" && (<div style={{width: "13%"}}>Balance</div>)}
					{role==="admin" ? <div style={{width: "13%"}}>Commission (%)</div> : ""}
					{/* <div>Company</div> */}
					{role==="agent" && (<div style={{width: "23%"}}>Actions</div>)}
					{role==="admin" && (<div style={{width: "27%"}}>Actions</div>)}
				</div>
				<div className="table-div-parent">				
					{users && users.map((u, index) => <Row user={u} key={u.id} index={index} />)}
				</div>
			</div>
			{totalItems > offset && (<div>
				<div style={{display: "flex", marginTop: "40px"}}>
					<div style={{marginLeft: "47%"}}>
						<span className="label-depth-shadow black" 
							style={{padding: "10px", width: "100px", fontSize: "20px"}}>
							{currentPage + 1} / {totalPages}
						</span>
					</div>
					<div style={{display: "flex", width: "100%", justifyContent: "right"}}>
						<span className="label-depth-shadow black" 
							style={{padding: "10px", marginRight: "50px", width: "200px", fontSize: "20px"}}>
							{startRecord} - {endRecord} of {totalItems}
						</span>
						<button disabled={offset <= 0 ? true : false} 
							onClick={decrement}
							className="board-buttons board-buttons-nav-bar-dark"
							style={{height: "50px", width: "80px", marginRight: "20px"}}>
							<img className="prev-img" src={PrevImage} />
						</button>
						<button disabled={endRecord >= totalItems ? true : false} 
							onClick={increment}
							className="board-buttons board-buttons-nav-bar-dark"
							style={{height: "50px", width: "80px"}}>
							<img className="next-img" src={NextImage} />
						</button>
					</div>
				</div>
			</div>)}
			<div>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: 3,
						'& > *': {
						m: 1,
						},
					}}
					>
					{/* <ButtonGroup size="small" aria-label="small button group">
						{buttons}
					</ButtonGroup>
					<ButtonGroup color="secondary" aria-label="medium secondary button group">
						{buttons}
					</ButtonGroup> */}
					<ButtonGroup size="large" aria-label="large button group">
						{buttons}
					</ButtonGroup>
				</Box>
			</div>
		</main>
	</>
  );
};

export default BoardAdminPagination;