import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { overrideResult } from "../actions/auth";
import { httpHelpers } from "../services/httpHelpers"
import { Navigate, useNavigate  } from 'react-router-dom';
import GameCardContent from "./GameCardContent";
import { logout } from "../actions/auth";
import { clearMessage } from "../actions/message";
import TokenService from "../services/token-service";
// import WinnerCheck from '../assets/icons8-done.gif';

const GameBoard2 = () => {
    const Ref = useRef(null);
    const [status, setStatus] = useState();
    const [timer, setTimer] = useState();
    let myPost = null;
    let timeLeft = 0;
    let wait = 0;

    const ZERO = 0;
    const ONE = 1;
    const TWO = 2;
    const THREE = 3;
    const FOUR = 4;
    const FIVE = 5;
    const SIX = 6;
    const SEVEN = 7;
    const EIGHT = 8;
    const NINE = 9;

    const [one, setOne] = useState();
    const [two, setTwo] = useState();
    const [three, setThree] = useState();
    const [four, setFour] = useState();
    const [five, setFive] = useState();
    const [six, setSix] = useState();
    const [seven, setSeven] = useState();
    const [eight, setEight] = useState();
    const [nine, setNine] = useState();
    const [ten, setTen] = useState();

    const [winOne, setWinOne] = useState();
    const [winTwo, setWinTwo] = useState();
    const [winThree, setWinThree] = useState();
    const [winFour, setWinFour] = useState();
    const [winFive, setWinFive] = useState();
    const [winSix, setWinSix] = useState();
    const [winSeven, setWinSeven] = useState();
    const [winEight, setWinEight] = useState();
    const [winNine, setWinNine] = useState();
    const [winTen, setWinTen] = useState();

    const [colorOne, setColorOne] = useState();
    const [colorTwo, setColorTwo] = useState();
    const [colorThree, setColorThree] = useState();
    const [colorFour, setColorFour] = useState();
    const [colorFive, setColorFive] = useState();
    const [colorSix, setColorSix] = useState();
    const [colorSeven, setColorSeven] = useState();
    const [colorEight, setColorEight] = useState();
    const [colorNine, setColorNine] = useState();
    const [colorTen, setColorTen] = useState();
    const redColorCode = "#FF0000";
    const greenColorCode = "#04AA6D";
    const purpleColorCode = "#9C27B0";

    const [gameId, setGameId] = useState();
    const [winner, setWinner] = useState();
    const [gameOdds, setGameOdds] = useState();
    const [serverWinner, setServerWinner] = useState("");
    const [winnerIndex, setWinnerIndex] = useState(-1);
    const [showButtons, setShowButtons] = useState(false);
    const [showError, setShowError] = useState(true);

    const url = "alpha/currentGameOdds";
    const url2 = "alpha/override/result";
	const api = httpHelpers()

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const [successful, setSuccessful] = useState(false);
    let navigate = useNavigate();

    const fetchGameOdds = () => {
		api
			.get(`${url}`)
			.then(res => {
				// console.log("odds res", res.data)
				myPost = res.data;
                console.log("myPost", myPost);
                timeLeft = myPost.timeLeft;
                //clearTimer(getDeadTime());
                if (timeLeft >= 0 && timeLeft < 3) {
                    setRemainingTime(5);
                } else {
                    //setStatus("Game In Progress...");
                }
                console.log("myPost.timeLeft", myPost.timeLeft);
                if (myPost.oddsMap) {
                    const oddsMap = myPost.oddsMap;
                    setGameOdds(oddsMap);
                    setAllOdds(oddsMap);
                    setGameId(myPost.gameId);
                }
			})
			.catch(err => {
                console.log(err.response.status)
                if (err.response.status === 401) {
                    TokenService.removeUser("admin");
                    logOut();
                    window.location.reload();
					// logOut("admin");
				}
            });
	}

    const setAllOdds = (oddsMap) => {
        Object.keys(oddsMap).map(function (key, index) {
            // console.log("oddsMap " + index, key, oddsMap[key]);
            // console.log("index 0: ", getOddsColor(key));
            if (index === 0) {
                setOne(oddsMap[key]);
                setWinOne(key);
                setColorOne(getOddsColor(key));
            } else if (index === 1) {
                setTwo(oddsMap[key]);
                setWinTwo(key);
                setColorTwo(getOddsColor(key));
            } else if (index === 2) {
                setThree(oddsMap[key]);
                setWinThree(key);
                setColorThree(getOddsColor(key));
            } else if (index === 3) {
                setFour(oddsMap[key]);
                setWinFour(key);
                setColorFour(getOddsColor(key));
            } else if (index === 4) {
                setFive(oddsMap[key]);
                setWinFive(key);
                setColorFive(getOddsColor(key));
            } else if (index === 5) {
                setSix(oddsMap[key]);
                setWinSix(key);
                setColorSix(getOddsColor(key));
            } else if (index === 6) {
                setSeven(oddsMap[key]);
                setWinSeven(key);
                setColorSeven(getOddsColor(key));
            } else if (index === 7) {
                setEight(oddsMap[key]);
                setWinEight(key);
                setColorEight(getOddsColor(key));
            } else if (index === 8) {
                setNine(oddsMap[key]);
                setWinNine(key);
                setColorNine(getOddsColor(key));
            } else if (index === 9) {
                setTen(oddsMap[key]);
                setWinTen(key);
                setColorTen(getOddsColor(key));
            }
        });
    }

    const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", "admin"));
		navigate("/logadmin");
	}, [dispatch]);

    const handleOverrideResult = (gameId, finalWinner) => {
        if (winnerIndex !== -1) {
            return;
        } else {
            setSuccessful(false);
            console.log("handleOverrideResult", gameId, finalWinner);
            dispatch(overrideResult(gameId, finalWinner))
                .then((data) => {
                    console.log("override data: ", data);
                    if (data.status === 401) {
                        TokenService.removeUser("admin");
                        logOut();
                        window.location.reload();
                        // logOut("admin");
                    } else {
                        if (data.status === 200) {
                            if (data.data.winner) {
                                Object.keys(gameOdds).map(function (key, index) {
                                    setServerWinner(data.data.winner);
                                    if (key === data.data.winner) {
                                        setWinnerIndex(index);
                                    }
                                });
                            }
                            setSuccessful(true);
                        } else {
                            setSuccessful(false);
                        }
                    }
                })
                .catch(() => {
                setSuccessful(false);
            });
        }
    };

    const getTimeRemaining = (e) => {
        timeLeft = timeLeft - 1;
        const seconds = timeLeft;
        return seconds;
    }

    const setRemainingTime = (e) => {
        timeLeft = 4;
    }

    const startTimer = (e) => {
        let seconds = getTimeRemaining(e);
        if (seconds >= 0) {
            console.log("timeLeft", timeLeft);
            if (timeLeft === 30) {
                setStatus("Result Processing...");
            } else if (timeLeft <= 25 && timeLeft >= 6) {
                if (timeLeft < 22 && timeLeft >= 6) {
                    setStatus("Submit Before 5 Seconds Left!");
                    setShowButtons(true);
                } 
                // else {
                //     fetchGameOdds();
                //     console.log("startTimer 25", timeLeft);
                // }
                fetchGameOdds();
                console.log("startTimer 25", timeLeft);
            } else if (timeLeft === 5) {
                fetchGameOdds();
                console.log("startTimer 5", timeLeft);
                setShowButtons(false);
                setStatus("Next Game Starts In...");
            } else if (timeLeft === 2) {
                setServerWinner("");
                setWinnerIndex(-1);
                setRemainingTime(5);
                wait++;
                if (wait === 3) {
                    fetchGameOdds();
                    wait = 0;
                    //setStatus("Waiting...");
                    setStatus("Game In Progress...");
                }
            }

            setTimer(seconds);
        }
    }

    const clearTimer = (e) => {
        // setTimer(timeLeft);

        // if (Ref.current) {
        //     clearInterval(Ref.current);
        // }

        // const id = setInterval (() => {
        //     startTimer(e);
        // }, 1000)
        // Ref.current = id;

        const id = setInterval (() => {
            startTimer(seconds => seconds + 5);
          }, 1000)
        return () => clearInterval(id);
    }

    // const getDeadTime = () => {
    //     let deadline = new Date();
    //     deadline.setSeconds(deadline.getSeconds() + 60);
    //     return deadline;
    // }

    useEffect(() => {
        if (!showError) {
            dispatch(clearMessage());
        } else {
            setShowError(false);
            fetchGameOdds();
            console.log("useEffect", timeLeft);
            // clearTimer();
            setStatus("Game In Progress...");
        }

        const id = setInterval (() => {
            startTimer(seconds => seconds + 5);
          }, 1000)
        return () => clearInterval(id);
    }, []);

    const onClickOne = (code, win) => {
        // console.log("One", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickTwo = (code, win) => {
        // console.log("Two", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickThree = (code, win) => {
        // console.log("Three", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickFour = (code, win) => {
        // console.log("Four", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickFive = (code, win) => {
        // console.log("Five", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickSix = (code, win) => {
        // console.log("Six", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickSeven = (code, win) => {
        // console.log("Seven", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickEight = (code, win) => {
        // console.log("Eight", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickNine = (code, win) => {
        // console.log("Nine", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const onClickTen = (code, win) => {
        // console.log("Ten", code, win);
        setWinner(win);
        handleOverrideResult(gameId, win);
    }

    const getOddsColor = (odd) => {
        if (odd === "ZERO" || odd === "FIVE") {
            return purpleColorCode;
        } else  if (odd === "ONE" || odd === "THREE" || odd === "SEVEN" || odd === "NINE") {
            return greenColorCode;
        } else  if (odd === "TWO" || odd === "FOUR" || odd === "SIX" || odd === "EIGHT") {
            return redColorCode;
        }
    }

    return (
        <div>
            <h2 style={{color: "black"}}>{status}</h2>
            <h1 style={{color: "black"}}>{timer}</h1>

            {showButtons && <div className="game-board-card-container">
                {/* console.log("colorOne", {colorOne}); */}
                <button className="card-game-board-default choice-default" style={{backgroundColor: colorOne}}
                    onClick={() => onClickOne(one, winOne)}>
                        <GameCardContent 
                            odds={one} winner={winOne} selected={winnerIndex === ZERO} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-2" style={{backgroundColor: colorTwo}}
                    onClick={() => onClickTwo(two, winTwo)}>
                        <GameCardContent 
                            odds={two} winner={winTwo} selected={winnerIndex === ONE} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-3" style={{backgroundColor: colorThree}}
                    onClick={() => onClickThree(three, winThree)}>
                        <GameCardContent 
                            odds={three} winner={winThree} selected={winnerIndex === TWO} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-4" style={{backgroundColor: colorFour}}
                    onClick={() => onClickFour(four, winFour)}>
                        <GameCardContent 
                            odds={four} winner={winFour} selected={winnerIndex === THREE} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-5" style={{backgroundColor: colorFive}}
                    onClick={() => onClickFive(five, winFive)}>
                        <GameCardContent 
                            odds={five} winner={winFive} selected={winnerIndex === FOUR} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-6" style={{backgroundColor: colorSix}}
                    onClick={() => onClickSix(six, winSix)}>
                        <GameCardContent 
                            odds={six} winner={winSix} selected={winnerIndex === FIVE} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-7" style={{backgroundColor: colorSeven}}
                    onClick={() => onClickSeven(seven, winSeven)}>
                        <GameCardContent 
                            odds={seven} winner={winSeven} selected={winnerIndex === SIX} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-8" style={{backgroundColor: colorEight}}
                    onClick={() => onClickEight(eight, winEight)}>
                        <GameCardContent 
                            odds={eight} winner={winEight} selected={winnerIndex === SEVEN} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-9" style={{backgroundColor: colorNine}}
                    onClick={() => onClickNine(nine, winNine)}>
                        <GameCardContent 
                            odds={nine} winner={winNine} selected={winnerIndex === EIGHT} winnerWidth={"60px"}/></button>
                <button className="card-game-board-default choice-best-0" style={{backgroundColor: colorTen}}
                    onClick={() => onClickTen(ten, winTen)}>
                        <GameCardContent 
                            odds={ten} winner={winTen} selected={winnerIndex === NINE} winnerWidth={"50px"}/></button>
            </div>}
            {/* {showButtons && <div className="game-board-card-container">
                
                {winnerIndex === ONE && <img src={WinnerCheck} />}
                {winnerIndex === TWO && <img src={WinnerCheck} />}
                {winnerIndex === THREE && <img src={WinnerCheck} />}
                {winnerIndex === FOUR && <img src={WinnerCheck} />}
                {winnerIndex === FIVE && <img src={WinnerCheck} />}
                {winnerIndex === SIX && <img src={WinnerCheck} />}
                {winnerIndex === SEVEN && <img src={WinnerCheck} />}
                {winnerIndex === EIGHT && <img src={WinnerCheck} />}
                {winnerIndex === NINE && <img src={WinnerCheck} />}

                {winnerIndex === ZERO && <div className="card-game-board-default choice-default" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === ONE && <div className="card-game-board-default choice-best-2" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === TWO && <div className="card-game-board-default choice-best-3" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === THREE && <div className="card-game-board-default choice-best-4" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === FOUR && <div className="card-game-board-default choice-best-5" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === FIVE && <div className="card-game-board-default choice-best-6" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === SIX && <div className="card-game-board-default choice-best-7" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === SEVEN && <div className="card-game-board-default choice-best-8" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === EIGHT && <div className="card-game-board-default choice-best-9" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
                {winnerIndex === NINE && <div className="card-game-board-default choice-best-0" 
                    style={{backgroundColor: "transparent"}}>
                        <img src={WinnerCheck} /></div>}
            </div>} */}
        </div>
    )
}

export default GameBoard2;