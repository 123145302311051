import React, { useState, useCallback } from "react"
import ReactDOM from "react-dom"
import Register from "./Register"
import UpdateAgent from "./UpdateAgent"
import Modal from "./Modal"
import Popup from './Popup';
import "../styles/Portal.css"
import { useDispatch, useSelector } from "react-redux";
import { block, logout, approveWithdraw } from "../actions/auth";
import UpdateForm from "./UpdateForm";
import SettleForm from "./SettleForm";
import WithdrawApproveUtr from "./WithdrawApproveUtr";
import { Navigate, useNavigate } from 'react-router-dom';
import TokenService from "../services/token-service";

const WithdrawApprovalTable = ({ requests, fetchWithdrawRequests }) => {
	const href = window.location.href;
	let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}
	const [showUpdate, setShowUpdate] = useState(false)
	const [isOpen, setIsOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [successful, setSuccessful] = useState(false);

	const togglePopup = (user) => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			setSelectedUser(user)
		}
	}

	const reload = () => {
		fetchWithdrawRequests();
	}

// 	const showPopup = (id) => {
// 		return <div>
//     <input
//       type="button"
//       value="Click to Open Popup"
//       onClick={togglePopup}
//     />
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//     {isOpen && <Popup
//       content={<>
//         <b>Design your Popup</b>
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//         <button>Test button</button>
//       </>}
//       handleClose={togglePopup}
//     />}
//   </div>
// 	}

	// const showUpdateUser = id => {
	// 	console.log("id", id)
	// 	return (
	// 		<div>
   	// 			<div id="register" className="modal">
	// 				{/* Modal content */}
	// 				<div className="modal-content">
	// 					<span className="close">&times;</span>
	// 					<div>
	// 						<div>{console.log("Test")}</div>
	// 						<Register user={upis.filter((user) => user.id === id)} />
	// 					</div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	)
	// }

	// const showRegister = (id) => {
	// 	<div id="register" className="modal">
   	// 		{/* Modal content */}
  	// 		<div className="modal-content">
    // 			<span className="close">&times;</span>
    // 			<div>
	// 				<div>{console.log("Test")}</div>
	// 				<Register upis={upis.filter((upi) => upi.id === id)} />
	// 			</div>
  	// 		</div>
	// 	</div>
	// }

	const { message } = useSelector(state => state.message);
  	const dispatch = useDispatch();
  	let navigate = useNavigate();

	const handleBlock = (user) => {
		dispatch(block(user.userName, user.accountStatus, role))
        .then((data) => {
			if (data === 401) {
				TokenService.removeUser(role);
				logOut();
				window.location.reload();
				// logOut(role);
			} else {
				setSuccessful(true);
		  		window.location.reload();
			}
        })
        .catch(() => {
          setSuccessful(false);
        });
	}

	const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

	const blockAgent = id => {
		const form = document.getElementsByClassName(`show-form-${id}`)
		form[0].classList.toggle("hide-form")
	}

	const showGames = id => {
		const form = document.getElementsByClassName(`show-form-${id}`)
		form[0].classList.toggle("hide-form")
	}

	const showWithdrawApprove = (id) => {
		const form = document.getElementsByClassName(`show-form-${id}`)
        form[0].classList.toggle("hide-form")
	}

	const handleApproveCancel = (id, withdrawState) => {
		setSuccessful(false);
		// if (isValidUtr(utrCode)) {
			dispatch(approveWithdraw(id, withdrawState, role, ""))
			.then((data) => {
			console.log("update withdraw: ", data);
				if (data === 401) {
					TokenService.removeUser(role);
					logOut();
					window.location.reload();
					// logOut(role);
				} else if (data === "Insuffcient Balance") {
					setSuccessful(false);
				} else {
					setSuccessful(true);
					window.location.reload();
				}
			})
			.catch(() => {
				setSuccessful(false);
			});
		// }
    }

	const showWithdrawRequest = (id, type) => {
		const form = document.getElementsByClassName(`show-form-${id}`+type)
        form[0].classList.toggle("hide-form")
	}

	const Row = ({ request }) => {
		return (
			<>	<div className='withdraw-row'>
					<div style={{paddingTop: "10px"}}>{request.userName}</div>
					<div style={{paddingTop: "10px"}}>{request.userId}</div>
					<div style={{paddingTop: "10px"}}>{request.balance}</div>
					<div style={{paddingTop: "10px"}}>{request.accountName}</div>
					<div style={{paddingTop: "10px"}}>{request.account}</div>
					<div style={{paddingTop: "10px"}}>{request.ifsc}</div>
					<div style={{paddingTop: "10px"}}>{request.upiId}</div>
					<div style={{paddingTop: "10px"}}>{request.amount}</div>
					<div style={{display: "flex", leftMargin: "auto", rightMargin: "auto"}}>
						{/* <button className="board-buttons board-buttons-nav-bar-dark"
							onClick={() => handleApproveCancel(request.id, "Approved")}>Approve</button>
						<button className="board-buttons board-buttons-nav-bar-dark"
							onClick={() => handleApproveCancel(request.id, "Blocked")}>Cancel</button> */}
						<button className="board-buttons board-buttons-nav-bar-dark"
							onClick={() => showWithdrawRequest(request.id, "A")}>Approve</button>
						<button className="board-buttons board-buttons-nav-bar-dark"
							onClick={() => showWithdrawRequest(request.id, "B")}>Reject</button>
						{/* <button className="board-buttons board-buttons-nav-bar-dark"
							onClick={() => handleApproveCancel(request.id, "Blocked")}>Cancel</button> */}
					</div>
				</div>
				<div className={`hide-form show-form-${request.id}A`}>
					<div>
                    <WithdrawApproveUtr withdraw={request} hidePopup={showWithdrawRequest} reload={reload} type={"A"} />
					</div>
                </div>
				<div className={`hide-form show-form-${request.id}B`}>
					<div>
                    <WithdrawApproveUtr withdraw={request} hidePopup={showWithdrawRequest} reload={reload} type={"B"} />
					</div>
                </div>
			</>
		)
	}

	return (
		<div>
			<div className='withdraw-titles'>
				<div>User Name</div>
				<div>User ID</div>
				<div>Balance</div>
				<div>Account Holder Name</div>
				<div>Bank Account</div>
				<div>IFSC</div>
				<div>UPI</div>
				<div>Amount</div>
				<div>Actions</div>
			</div>
			<div className="table-div-parent">
				<div>
						{/* <button 
						className="btn-add-user-submit" 
						onClick={() => showUpdateAgent(user.id)}>Update</button> */}
						{/* <input
						type="button"
						value="Click to Open Popup"
						onClick={togglePopup}
						/>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
						{isOpen && <Popup
						content={<>
							{/* <b>Design your Popup</b>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
							<Register show={false} selectedUser={selectedUser} />
						</>}
						handleClose={togglePopup}
						/>}
					</div>
				{requests && requests.map(w => <Row request={w} key={w.id} />)}
			</div>
		</div>
	)
}

export default WithdrawApprovalTable;