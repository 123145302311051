import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function MyDatePicker({handleStartDatePicker, handleEndDatePicker}) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  console.log(handleStartDatePicker)
  console.log(handleEndDatePicker)
  return (
    <DatePicker selected={selectedDate} 
    onChange={(date) => setSelectedDate(date.getTime())}  
    onSelect={handleStartDatePicker ? handleStartDatePicker(selectedDate) : handleEndDatePicker(selectedDate)} />
  );
};