import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LOGOUT } from "../actions/types";
import { clearMessage, setMessage } from "../actions/message";
import TokenService from "../services/token-service";
import { httpHelpers } from "../services/httpHelpers";

const UserBetHistory = ({ userName }) => {
  const href = window.location.href;
  let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}

  const { message } = useSelector(state => state.message);
  const [showError, setShowError] = useState(true);
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    if (!showError) {
			dispatch(clearMessage());
		}
		setShowError(false);
  }, []);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logOut = useCallback(() => {
    if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

  // console.log("message 1", message);
	if (message && message === LOGOUT) {
		console.log("message 3", message);
		TokenService.removeUser("agent");
		logOut();
		window.location.reload();
	}

  const api = httpHelpers();
  const [userBetHistory, setUserBetHistory] = useState(null);
  let isMounted = true;

  useEffect(() => {
    fetchUserBetHistory();
    return () => { isMounted = false };
  }, []);

  const fetchUserBetHistory = () => {
    const url = `beta/getLastBets?userName=${userName}`;
    api
        .get(`${url}`)
        .then(res => {
            if (isMounted) {
                console.log("bet hist res", res.data)
                setUserBetHistory(res.data);
            }
        })
        // .then((response) => response.data.users)
        // .then((allUsers) => setUsers(allUsers))
        .catch(err => {
            console.log(err.response.status)
            if (err.response.status === 401) {
                TokenService.removeUser("agent");
                logOut();
                window.location.reload();
                // logOut(role);
             } else {
                setSuccessful(false);
             }
        });
  }

  const Row = ({ bet, index }) => {
		return (
			<>	
                <div className='bet-history-row'>
					<div style={{paddingTop: "5px"}}>{bet.gameId}</div>
					<div style={{paddingTop: "5px"}}>{bet.betId}</div>
                    <div style={{paddingTop: "5px"}}>{bet.candidate}</div>
                    <div style={{paddingTop: "5px"}}>{bet.amount}</div>
                    <div style={{paddingTop: "5px"}}>{bet.gameWinner}</div>
				</div>
			</>
		)
	}

  return (
        <div>
			{message && (
				<div className="form-group">
					<div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
					{message}
					</div>
				</div>
			)}
			<div className='bet-history-titles'>
				<div>Game ID</div>
                <div>Bet ID</div>
                <div>Candidate</div>
                <div>Amount</div>
                <div>Winner</div>
			</div>
            <div className="bets-container">
				{userBetHistory && userBetHistory.map((b, index) => <Row bet={b} key={index} index={index} />)}
            </div>
		</div>
  );
};

export default UserBetHistory;