import React, { useState } from "react"
import ReactDOM from "react-dom"
import Register from "./Register"
import UpdateAgent from "./UpdateAgent"
import Modal from "./Modal"
import Popup from './Popup';
import MyCalendar from './MyCalendar';
// import "../styles/Portal.css"
import { useDispatch, useSelector } from "react-redux";
import { block } from "../actions/auth";
import UpdateForm from "./UpdateForm";
import Test from "./Test";

const ReportTable = ({ ledgers, postUser, updateUser, deleteUser }) => {
	const href = window.location.href;
	let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}
	const [showUpdate, setShowUpdate] = useState(false)
	const [isOpen, setIsOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [successful, setSuccessful] = useState(false);

	const togglePopup = (user) => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			setSelectedUser(user)
		}
	}

// 	const showPopup = (id) => {
// 		return <div>
//     <input
//       type="button"
//       value="Click to Open Popup"
//       onClick={togglePopup}
//     />
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//     {isOpen && <Popup
//       content={<>
//         <b>Design your Popup</b>
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//         <button>Test button</button>
//       </>}
//       handleClose={togglePopup}
//     />}
//   </div>
// 	}

	const showUpdateUser = id => {
		console.log("id", id)
		// const form = document.getElementsByClassName(`show-form-${id}`)
		// form[0].classList.toggle("hide-form")

		return (
			<div>
   				<div id="register" className="modal">
					{/* Modal content */}
					<div className="modal-content">
						<span className="close">&times;</span>
						<div>
							<div>{console.log("Test")}</div>
							<Register user={ledgers.filter((user) => user.id === id)} />
						</div>
					</div>
				</div>
			</div>
		)
	}

	const showRegister = (id) => {
		<div id="register" className="modal">
   			{/* Modal content */}
  			<div className="modal-content">
    			<span className="close">&times;</span>
    			<div>
					<div>{console.log("Test")}</div>
					<Register user={ledgers.filter((user) => user.id === id)} />
				</div>
  			</div>
		</div>
	}

	const { message } = useSelector(state => state.message);
  	const dispatch = useDispatch();
  	console.log(message)

	const handleBlock = (user) => {
		dispatch(block(user.userName, user.accountStatus, role))
        .then(() => {
          setSuccessful(true);
		  window.location.reload();
        })
        .catch(() => {
          setSuccessful(false);
        });
	}

	const blockAgent = id => {
		const form = document.getElementsByClassName(`show-form-${id}`)
		form[0].classList.toggle("hide-form")
	}

	const showGames = id => {
		const form = document.getElementsByClassName(`show-form-${id}`)
		form[0].classList.toggle("hide-form")
	}

	const showUpdateAgent = (userName) => {
		// setIsOpen(!isOpen);
		const form = document.getElementsByClassName(`show-form-${userName}`)
        form[0].classList.toggle("hide-form")
	}

	const Row = ({ ledger }) => {
		return (
			<>	<div className='report-row'>
					<div style={{paddingTop: "10px"}}>{ledger.date}</div>
					{ledger.debitmessage ? <div style={{paddingTop: "10px"}}>{ledger.debitmessage}</div> : <div>--</div>}
                    {ledger.creditmessage ? <div style={{paddingTop: "10px"}}>{ledger.creditmessage}</div> : <div>--</div>}
                    {ledger.othermessage ? <div style={{paddingTop: "10px"}}>{ledger.othermessage}</div> : <div>--</div>}
					<div style={{paddingTop: "10px"}}>{ledger.amount}</div>
                    {/* <div>{user.fromUserType}</div>
                    <div>{user.addedBalance}</div> */}
                    {/* {user.entryType==="CREDIT" ? (
                        <div style={{color: "var(--green-color)"}}>{user.entryType}</div>
                    ) : (
                        <div style={{color: "var(--red-color)"}}>{user.entryType}</div>
                    )}
                    {user.entryType==="CREDIT" ? (
                        <div style={{color: "var(--green-color)"}}>{user.addedBalance}</div>
                    ) : (
                        <div style={{color: "var(--red-color)"}}>{user.addedBalance}</div>
                    )} */}
				</div>
			</>
		)
	}

	return (
		<div>
			<div className='report-titles'>
				<div>Date</div>
                <div>Debit</div>
                <div>Credit</div>
                <div>Other</div>
                <div>Amount</div>
			</div>
			<div>
				{ledgers && ledgers.map((l, index) => <Row ledger={l} key={index}/>)}
			</div>
		</div>
	)
}

export default ReportTable