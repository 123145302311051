import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../services/token-service";
import { register, logout } from "../actions/auth";
import TextField from "@material-ui/core/TextField";

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  
  const validEmail = (value) => {
    if (!isEmail(value)) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid email.
        </div>
      );
    }
  };
  
  const vusername = (value) => {
    if (value.length < 3 || value.length > 20) {
      return (
        <div className="alert alert-danger" role="alert">
          The username must be between 3 and 20 characters.
        </div>
      );
    }
  };
  
  const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className="alert alert-danger" role="alert">
          The password must be between 6 and 40 characters.
        </div>
      );
    }
  };
  
  const validPhone = (value) => {
    // console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid phone.
        </div>
      );
    }
  };

  const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter in valid range (0 - 100).
        </div>
      );
    }
  };

export default function Test({user, hidePopup, fetchUpdatedRecords}) {
    // console.log("Test", user)
    const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const form = useRef();
  const checkBtn = useRef();

  const [name, setName] = useState("");
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enterPassword, setEnterPassword] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [balance, setBalance] = useState(0);
  const [commission, setCommission] = useState(0);
  const [successful, setSuccessful] = useState(false);
  const [load, setLoadData] = useState(true);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (load) {
    setName(user.name);
    setUsername(user.userName);
    // setPassword(user.password);
    setPhone(user.phoneNumber);
    setBalance(user.balance);

    if (role==="admin") {
      setCommission(user.adminCommission);
    }
    setLoadData(false);
  }

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeEnterPassword = (e) => {
    const enterpassword = e.target.value;
    setEnterPassword(enterpassword);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeBalance = (e) => {
    const balance = e.target.value;
    setBalance(balance);
  };

  const onChangeCommission = (e) => {
    const commission = e.target.value;
    setCommission(commission);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      // const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ";
      // const refreshToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ";

      dispatch(register(name, userName, email, password, phoneNumber, balance, commission, enterPassword, role, true))
        .then((data) => {
          console.log("update data: ", data);
          if (data.status === 401) {
            if (data.data === "Wrong password" || data.data === "Insuffcient Balance") {
              setSuccessful(false);
            } else {
              TokenService.removeUser(role);
              logOut();
              window.location.reload();
              // logOut(role);
            }
          } else if (data.status === 200) {
            setSuccessful(true);
            fetchUpdatedRecords();
            // window.location.reload();
          } else {
            setSuccessful(false);  
          }
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };

  const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

    return (
        <div className="portal-backdrop">
        <div className="portal">
        <div>
            <button className="board-buttons board-buttons-nav-bar-dark-smallest right" onClick={() => hidePopup(user.userName)}>X</button>
        </div>
        <div>
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label className="label" htmlFor="username">{role === "admin" ? "Agent ID" : "User ID"}</label>
                {/* <div align="left" className="form-control">{userName}</div> */}
                <TextField
                  value={userName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="userName"
                  name="userName"
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="form-group">
                <label className="label" htmlFor="name">{role === "admin" ? "Agent Name" : "User Name"}</label>
                {/* <Input
                  id="reg_name"
                  type="text"
                  className="form-control"
                  name="name"
                  maxLength="20"
                  value={name}
                  onChange={onChangeName}
                  validations={!successful && [required]}
                /> */}
                {/* <input value={name}></input> */}
                <TextField
                  value={name}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  onChange={onChangeName}
                  name="name"
                  autoFocus
                  autoComplete="off"
                />
                
              </div>
              <div className="form-group">
                <label className="label" htmlFor="password">{role === "admin" ? "Agent Password" : "User Password"}</label>
                <TextField
                  value={password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  onChange={onChangePassword}
                  name="password"
                  autoFocus
                  autoComplete="off"
                  type="password"
                />
                {/* <Input
                  id="reg_password"
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={!successful && [required, vpassword]}
                /> */}
              </div>

              <div className="form-group">
                <label className="label" htmlFor="phone">{role === "admin" ? "Agent Phone" : "User Phone"}</label>
                <TextField
                  value={phoneNumber}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  onChange={onChangePhone}
                  name="phone"
                  autoFocus
                  autoComplete="off"
                />
                {/* <Input
                  id="reg_phone"
                  type="tel"
                  className="form-control"
                  name="phone"
                  maxLength="10"
                  value={phoneNumber}
                  onChange={onChangePhone}
                  validations={!successful && [required, validPhone]}
                /> */}
              </div>

              <div className="form-group">
                <label className="label" htmlFor="balance">{role === "admin" ? "Initial Agent Balance" : "Initial User Balance"}</label>
                <TextField
                  value={balance}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="balance"
                  onChange={onChangeBalance}
                  name="balance"
                  autoFocus
                  autoComplete="off"
                />
                {/* <Input
                  id="reg_balance"
                  type="tel"
                  className="form-control"
                  name="balance"
                  value={balance}
                  onChange={onChangeBalance}
                  validations={!successful && [required]}
                /> */}
              </div>
              {role==="admin" && (<div className="form-group">
                <label className="label" htmlFor="comission">Agent Commission (%)</label>
                <TextField
                  value={commission}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="commission"
                  onChange={onChangeCommission}
                  name="commission"
                  autoFocus
                  autoComplete="off"
                />
                {/* <Input
                  id="reg_commission"
                  type="tel"
                  className="form-control"
                  name="commission"
                  value={commission}
                  onChange={onChangeCommission}
                  validations={!successful && [required, validCommission]}
                /> */}
              </div>)}

              <div className="form-group">
                <label className="label" htmlFor="enterPassword">Enter Your Password</label>
                <TextField
                  value={enterPassword}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="enterPassword"
                  onChange={onChangeEnterPassword}
                  name="enterPassword"
                  autoFocus
                  autoComplete="off"
                  type="password"
                />
                {/* <Input
                  id="enterPassword"
                  type="password"
                  className="form-control"
                  name="enterPassword"
                  value={enterPassword}
                  onChange={onChangeEnterPassword}
                  validations={!successful && [required, vpassword]}
                /> */}
              </div>

              <div className="form-group" style={{marginTop: "30px"}}>
                <button className="board-buttons board-buttons-nav-bar-dark-small">Update</button>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        </div>

      {successful && (<div className="form-group">
        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
          {userName} is Updated Successfully!
        </div>
      </div>)}
      </div>
    </div>
    )
}