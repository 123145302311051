import { useState } from 'react';

export default function UserTreeLevel({ level }) {
    const href = window.location.href;
    let role = "admin";
    if (href.includes("agent")) {
      role = "agent";
    }

    console.log("l", level);
  
    const Row = ({ reward, index }) => {
          return (
            <>	
              <div className='tree-level-row'>
                <div style={{paddingTop: "5px"}}>{reward.fromUserId}</div>
                <div style={{paddingTop: "5px"}}>{reward.waterReward}</div>
                <div style={{paddingTop: "5px"}}>{reward.firstReward}</div>
              </div>
            </>
          )
      }
  
    return (
        <div>
          <div className='tree-level-titles'>
              <div>From ID</div>
              <div>Water Reward</div>
              <div>First Reward</div>
          </div>
          <div className="levels-container">
            {level && level.map((reward, index) => <Row reward={reward} key={index} index={index} />)}
          </div>
        </div>
    );
  };