class TokenService {
    getUserName(role) {
      const user = JSON.parse(localStorage.getItem(role));
      return user?.userName;
    }
    getLocalRefreshToken(role) {
      const user = JSON.parse(localStorage.getItem(role));
      return user?.refreshToken;
    }
  
    getLocalAccessToken(role) {
      const user = JSON.parse(localStorage.getItem(role));
      return user?.jwt;
    }
  
    updateLocalAccessToken(token, role) {
      let user = JSON.parse(localStorage.getItem(role));
      user.jwt = token;
      localStorage.setItem(role, JSON.stringify(user));
    }
  
    getUser(role) {
      return JSON.parse(localStorage.getItem(role));
    }
  
    setUser(user, role) {
      console.log("setUser", role)
      console.log(role, JSON.stringify(user));
      localStorage.setItem(role, JSON.stringify(user));
    }
  
    removeUser(role) {
      console.log("removeUser", role)
      localStorage.removeItem(role);
    }
  }
  
  export default new TokenService();