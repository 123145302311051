import React, { useState, useCallback } from "react"
import ReactDOM from "react-dom"
import Register from "./Register"
import UpdateAgent from "./UpdateAgent"
import Modal from "./Modal"
import Popup from './Popup';
import "../styles/Portal.css"
import { useDispatch, useSelector } from "react-redux";
import { block, logout } from "../actions/auth";
import UpdateForm from "./UpdateForm";
import SettleForm from "./SettleForm";
import { Navigate, useNavigate } from 'react-router-dom';
import TokenService from "../services/token-service";

const UnsettledTable = ({ users, postUser, updateUser, deleteUser }) => {
	const href = window.location.href;
	let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}
	const [showUpdate, setShowUpdate] = useState(false)
	const [isOpen, setIsOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [successful, setSuccessful] = useState(false);

	const togglePopup = (user) => {
		setIsOpen(!isOpen);
		if (!isOpen) {
			setSelectedUser(user)
		}
	}

// 	const showPopup = (id) => {
// 		return <div>
//     <input
//       type="button"
//       value="Click to Open Popup"
//       onClick={togglePopup}
//     />
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//     {isOpen && <Popup
//       content={<>
//         <b>Design your Popup</b>
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//         <button>Test button</button>
//       </>}
//       handleClose={togglePopup}
//     />}
//   </div>
// 	}

	const showUpdateUser = id => {
		console.log("id", id)
		// const form = document.getElementsByClassName(`show-form-${id}`)
		// form[0].classList.toggle("hide-form")

		return (
			<div>
   				<div id="register" className="modal">
					{/* Modal content */}
					<div className="modal-content">
						<span className="close">&times;</span>
						<div>
							<div>{console.log("Test")}</div>
							<Register user={users.filter((user) => user.id === id)} />
						</div>
					</div>
				</div>
			</div>
		)
	}

	const showRegister = (id) => {
		<div id="register" className="modal">
   			{/* Modal content */}
  			<div className="modal-content">
    			<span className="close">&times;</span>
    			<div>
					<div>{console.log("Test")}</div>
					<Register user={users.filter((user) => user.id === id)} />
				</div>
  			</div>
		</div>
	}

	const { message } = useSelector(state => state.message);
  	const dispatch = useDispatch();
  	let navigate = useNavigate();

	const handleBlock = (user) => {
		dispatch(block(user.userName, user.accountStatus, role))
        .then((data) => {
			if (data === 401) {
				TokenService.removeUser(role);
				logOut();
				window.location.reload();
				// logOut(role);
			} else {
				setSuccessful(true);
		  		window.location.reload();
			}
        })
        .catch(() => {
          setSuccessful(false);
        });
	}

	const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

	const blockAgent = id => {
		const form = document.getElementsByClassName(`show-form-${id}`)
		form[0].classList.toggle("hide-form")
	}

	const showGames = id => {
		const form = document.getElementsByClassName(`show-form-${id}`)
		form[0].classList.toggle("hide-form")
	}

	const showSettleForm = (userName) => {
		const form = document.getElementsByClassName(`show-form-${userName}`)
        form[0].classList.toggle("hide-form")
	}

	const Row = ({ user }) => {
		return (
			<>	<div className='unsettled-row'>
					<div style={{paddingTop: "10px"}}>{user.userName}</div>
					<div style={{paddingTop: "10px"}}>{user.phoneNo}</div>
					<div style={{paddingTop: "10px"}}>{(user.unsettledBalance).toFixed(1)}</div>
					<div>
						<button className="board-buttons board-buttons-nav-bar-dark"
						onClick={() => showSettleForm(user.userName)}>
							Settle
						</button>
						{/* <button className={user.accountStatus==="BLOCKED" ? "board-buttons board-buttons-red" : "board-buttons board-buttons-green"}
						onClick={() => handleBlock(user)} >
							{user.accountStatus==="BLOCKED" ? "Unblock" : "Block"}
						</button>
						<button className="board-buttons board-buttons-green">Games</button> */}
					</div>
					{/* {role==="agent" && (<div style={{display: "flex", width: "28.6%"}}>
						<button className="board-buttons board-buttons-green"
						onClick={() => showUpdateAgent(user.userName)}>
							Edit
						</button>
						<button className={user.accountStatus==="BLOCKED" ? "board-buttons board-buttons-red" : "board-buttons board-buttons-green"}
						onClick={() => handleBlock(user)} >
							{user.accountStatus==="BLOCKED" ? "Unblock" : "Block"}
						</button>
						<button className="board-buttons board-buttons-green">Games</button>
					</div>)} */}
				</div>
				<div className={`hide-form show-form-${user.userName}`}>
					<div>
                    	{/* <Register selectedUser={user} show="false"/> */}
						{/* <label>{user.userName}</label> */}
						<SettleForm user={user} showSettleForm={showSettleForm}/>
						{/* <Popup content={
						<>
							<Test user={user} showUpdateAgent={showUpdateAgent}/>
						</>}
						handleClose={togglePopup}
						/> */}
					</div>
                </div>
				{/* <div className={`hide-form show-form-${user.id}`}>
					<Form userData={user} postUser={postUser} updateUser={updateUser} />
					<Register user={user} />
				</div> */}
				{/* <p>This is the main content. To display a lightbox click <a href="javascript:void(0)" onclick="document.getElementById('light').style.display='block';document.getElementById('fade').style.display='block'">here</a></p>
  				<div id="light" 
				class="white_content">
					This is the lightbox content. 
					<a href="javascript:void(0)" 
					onclick="document.getElementById('light').style.display='none';document.getElementById('fade').style.display='none'">
						Close</a>
  				</div>
  <				div id="fade" class="black_overlay"></div> */}
  {/* The Modal */}
			</>
		)
	}

	return (
		<div>
			<div className='unsettled-titles'>
				<div>User Name</div>
				<div>Phone</div>
				<div>Balance</div>
				<div>Actions</div>
			</div>
			<div>
				<div>
						{/* <button 
						className="btn-add-user-submit" 
						onClick={() => showUpdateAgent(user.id)}>Update</button> */}
						{/* <input
						type="button"
						value="Click to Open Popup"
						onClick={togglePopup}
						/>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
						{isOpen && <Popup
						content={<>
							{/* <b>Design your Popup</b>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
							<Register show={false} selectedUser={selectedUser} />
						</>}
						handleClose={togglePopup}
						/>}
					</div>
				{users && users.map(u => <Row user={u} key={u.userName} />)}
			</div>
		</div>
	)
}

export default UnsettledTable