import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LOGOUT } from "../actions/types";
import { clearMessage, setMessage } from "../actions/message";
import TokenService from "../services/token-service";
import { httpHelpers } from "../services/httpHelpers";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import UserTreeLevel from './UserTreeLevel';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="user-detail-tabs-right-pane"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const UserTreeTable = ({ userName }) => {
  const href = window.location.href;
  let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { message } = useSelector(state => state.message);
  const [showError, setShowError] = useState(true);
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    if (!showError) {
			dispatch(clearMessage());
		}
		setShowError(false);
  }, []);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logOut = useCallback(() => {
    if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

  // console.log("message 1", message);
	if (message && message === LOGOUT) {
		console.log("message 3", message);
		TokenService.removeUser("agent");
		logOut();
		window.location.reload();
	}

  const api = httpHelpers();
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  
  const url = `beta/getRewardsEarned?userName=${userName}`;

  useEffect(() => {
    fetchUserTreeLevels();
  }, []);

  const fetchUserTreeLevels = () => {
    api
      .get(`${url}`)
      .then(res => {
        console.log("user tree res", res.data);
        setValue(0);
        setLevel1(res.data[0]);
        setLevel2(res.data[1]);
        setLevel3(res.data[2]);
    })
    // .then((response) => response.data.users)
    // .then((allUsers) => setUsers(allUsers))
    .catch(err => {
      console.log(err.response.status)
      if (err.response.status === 401) {
      TokenService.removeUser("agent");
      logOut();
      window.location.reload();
      // logOut(role);
      } else {
        setSuccessful(false);
      }
    });
  }

  const Row = ({ user, index }) => {
		return (
			<>	
          <div className='bet-history-row'>
					<div style={{paddingTop: "5px"}}>{user.gameId}</div>
					<div style={{paddingTop: "5px"}}>{user.betId}</div>
          <div style={{paddingTop: "5px"}}>{user.candidate}</div>
          <div style={{paddingTop: "5px"}}>{user.amount}</div>
          <div style={{paddingTop: "5px"}}>{user.gameWinner}</div>
				</div>
			</>
		)
	}

  return (
        <div>
			{message && (
				<div className="form-group">
					<div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
					{message}
					</div>
				</div>
			)}
			<Box sx={{ width: '100%' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab label="Level 1" {...a11yProps(0)}/>
          <Tab label="Level 2" {...a11yProps(1)}/>
          <Tab label="Level 3" {...a11yProps(2)}/>
        </Tabs>
        <TabPanel value={value} index={0}>
          <UserTreeLevel level={level1}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UserTreeLevel  level={level2}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UserTreeLevel  level={level3}/>
        </TabPanel>
      </Box>
		</div>
  );
};

export default UserTreeTable;