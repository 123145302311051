import React from "react";
import ImageUploading from "react-images-uploading";

export default function ImageUpload({setBase64Image}) {
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const maxFileSize = 1024 * 1024;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  return (
    <div>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        maxFileSize={maxFileSize}
        acceptType={["jpg", "png", "jpeg"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          // write your building UI
          <div style={{marginLeft: "auto"}}>
            {errors && <div>
              {errors.maxNumber && <span style={{color: "red"}}>Only 1 file can be uploaded.</span>}
              {errors.acceptType && <span style={{color: "red"}}>Your selected file type is not allowed.</span>}
              {errors.maxFileSize && <span style={{color: "red"}}>1 Mb file size limit exceeded.</span>}
              {errors.resolution && <span style={{color: "red"}}>Selected file is not match your desired resolution.</span>}
              {/* {errors = ""} */}
            </div>}
            <div style={{display: "flex", marginTop: "5px"}}>
            <button type="button" className="board-buttons board-buttons-green"
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload QR Code
            </button>
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.data_url} alt="" width="100" />
                {setBase64Image(image.data_url)}
                <div className="image-item__btn-wrapper">
                  {/* <button type="button" className="board-buttons board-buttons-green-smallest"
                  onClick={() => onImageUpdate(index)}>Update</button> */}
                  <button type="button" className="board-buttons board-buttons-green-small"
                  onClick={() => onImageRemove(index)}>Remove</button>
                </div>
              </div>
            ))}
            {/* &nbsp;
            <button onClick={onImageRemoveAll}>Remove</button> */}            
            </div>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}