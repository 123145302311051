import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../services/token-service";
import { register, logout } from "../actions/auth";
import "../index.css"
import { updateBalance } from "../App.js";
import { clearMessage } from "../actions/message";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vusername = (value) => {
  const re = /^\S*$/;
  if (!re.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        User Name can not contain spaces.
      </div>
    );
  } else if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

const validPhone = (value) => {
  console.log(value)
  if (!isMobilePhone(value) || value.length < 10) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid phone.
      </div>
    );
  }
};

const validCommission = (value) => {
  console.log(value)
  if (value > 100 || value < 0) {
    return (
      <div className="alert alert-danger" role="alert">
        Please enter in valid range (0 - 100).
      </div>
    );
  }
};

// const validBalance = (value) => {
//   console.log(value)
//   if (!isMobilePhone(value) || value.length < 10) {
//     return (
//       <div className="alert alert-danger" role="alert">
//         This is not a valid phone.
//       </div>
//     );
//   }
// };

const Register = () => {
  const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const form = useRef();
  const checkBtn = useRef();

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [balance, setBalance] = useState("");
  const [commission, setCommission] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [showError, setShowError] = useState(true);

  useEffect(() => {
    // if (!showError) {
    //   dispatch(clearMessage());
    // }
    // setShowError(false);
    dispatch(clearMessage());
  }, [])
  // console.log(user.name);
  // console.log(user.userName);
  // console.log(user.password);
  // console.log(user.phoneNumber);
  // console.log(user.balance);

  // if (selectedUser) {
  //   setName(selectedUser.name);
  //   setUsername(selectedUser.userName);
  //   setPassword(selectedUser.password);
  //   setPhone(selectedUser.phoneNumber);
  //   setBalance(selectedUser.balance);
  // }

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeBalance = (e) => {
    const balance = e.target.value;
    setBalance(balance);
  };

  const onChangeCommission = (e) => {
    const commission = e.target.value;
    setCommission(commission);
  };

  const handleRegister = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      // const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ";
      // const refreshToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ";
      console.log("register user", role);
      dispatch(register(name, username, email, password, phone, balance, commission, "", role))
        .then((data) => {
          console.log("reg data: ", data);
          if (data.status === 401) {
            if (data.data === "Wrong password") {
              setSuccessful(false);
            } else {
              TokenService.removeUser(role);
              logOut();
              window.location.reload();
                // logOut(role);
            }
          } else if (data.data === "Admin balance not sufficient" || data.data === "Insuffcient Balance") {
            setSuccessful(false);
          } else if (data.status === 200) {
            setSuccessful(true);
          }
        })
        .catch(() => {
        });
    }
  };

  const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

  const handleAddMore = () => {
    setName("");
    setUsername("");
    setEmail("");
    setPassword("");
    setPhone("");
    setBalance("");
    setCommission("");
    setSuccessful(false);
  }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label className="label" htmlFor="name">Name</label>
                <Input
                  id="reg_name"
                  type="text"
                  className="form-control"
                  name="name"
                  maxLength="20"
                  value={name}
                  onChange={onChangeName}
                  validations={!successful && [required]}
                />
              </div>
              <div className="form-group">
                <label className="label" htmlFor="username">Username</label>
                <Input
                  id="reg_username"
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={!successful && [required, vusername]}
                />
              </div>

              <div className="form-group">
                <label className="label" htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={!successful && [required, validEmail]}
                />
              </div>
              <div className="form-group">
                <label className="label" htmlFor="password">Password</label>
                <Input
                  id="reg_password"
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={!successful && [required, vpassword]}
                />
              </div>

              <div className="form-group">
                <label className="label" htmlFor="phone">Phone</label>
                <Input
                  id="reg_phone"
                  type="tel"
                  className="form-control"
                  name="phone"
                  maxLength="10"
                  value={phone}
                  onChange={onChangePhone}
                  validations={!successful && [required, validPhone]}
                />
              </div>

              <div className="form-group">
                <label className="label" htmlFor="balance">Initial Balance</label>
                <Input
                  id="reg_balance"
                  type="tel"
                  className="form-control"
                  name="balance"
                  value={balance}
                  onChange={onChangeBalance}
                  validations={!successful && [required]}
                />
              </div>
              {role==="admin" && (<div className="form-group">
                <label className="label" htmlFor="commission">Admin Commission (%)</label>
                <Input
                  id="reg_commission"
                  type="tel"
                  className="form-control"
                  name="commission"
                  value={commission}
                  onChange={onChangeCommission}
                  validations={!successful && [required, validCommission]}
                />
              </div>)}

              <div className="form-group" style={{marginTop: "30px"}}>
                <button className="board-buttons board-buttons-nav-bar-dark-smaller">Add</button>
              </div>
            </div>
          )}

            {/* <div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block">Add</button>
              </div>
            </div> */}

          {/* {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )} */}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>

      {(message || successful) && (<div className="form-group">
        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
          {message ? message : (username + " is Added Successfully!")}
        </div>
      </div>)}
      {successful && (<div>
          <button className="btn btn-primary btn-block" onClick={handleAddMore}>Add More</button>
        </div>)}
    </div>
  );
};

export default Register;