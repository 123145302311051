import React, { useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isMobilePhone } from "validator";
import TokenService from "../services/token-service";
import { block, logout } from "../actions/auth";
import TextField from "@material-ui/core/TextField";

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };
  
  const vpassword = (value) => {
    if (value.length < 6 || value.length > 40) {
      return (
        <div className="alert alert-danger" role="alert">
          The password must be between 6 and 40 characters.
        </div>
      );
    }
  };
  
  const validPhone = (value) => {
    console.log(value)
    if (!isMobilePhone(value) || value.length < 10) {
      return (
        <div className="alert alert-danger" role="alert">
          This is not a valid phone.
        </div>
      );
    }
  };

  const validCommission = (value) => {
    console.log(value)
    if (value > 100 || value < 0) {
      return (
        <div className="alert alert-danger" role="alert">
          Please enter in valid range (0 - 100).
        </div>
      );
    }
  };

export default function Block({user, index, showBlock, logout, fetchUpdatedRecords}) {
    // console.log("Test", user)
    const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const form = useRef();
  const checkBtn = useRef();

  const [name, setName] = useState("");
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [amount, setAmount] = useState(0);
  const [commission, setCommission] = useState(0);
  const [successful, setSuccessful] = useState(false);
  const [load, setLoadData] = useState(true);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  if (load) {
    setName(user.name);
    setUsername(user.userName);
    setLoadData(false);
  }

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);
  };

  const onChangeAmount = (e) => {
    const amnt = e.target.value;
    setAmount(amnt);
  };

  const onChangeCommission = (e) => {
    const commission = e.target.value;
    setCommission(commission);
  };

//   const handleBlock = (user) => {
//     dispatch(block(user.userName, user.accountStatus, role))
//     .then((data) => {
//         if (data === 401) {
//             logOut();
//         } else {
//             setSuccessful(true);
//               window.location.reload();
//         }
//     })
//     .catch(() => {
//       setSuccessful(false);
//     });
//   }

  const handleBlock = (e) => {
    console.log("block", user.accountStatus);
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      // const accessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ";
      // const refreshToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ";
      
        dispatch(block(user.userName, user.accountStatus, password, role))
            .then((data) => {
            console.log("block data: ", data);
            if (data.status === 401) {
                if (data.data === "Wrong password") {
                    setSuccessful(false);
                } else {
                  TokenService.removeUser(role);
                  logOut();
                  window.location.reload();
                  // logout(role);
                }
            } else if (data.status === 200) {
                setSuccessful(true);
                fetchUpdatedRecords();
                // window.location.reload();
            } else {
              setSuccessful(false);
            }
            })
            .catch(() => {
            setSuccessful(false);
            });
    }
  };

  const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

    return (
        <div className="portal-backdrop">
        <div className="portal">
        <div>
            <button className="board-buttons board-buttons-nav-bar-dark-smallest right" onClick={() => showBlock(user.userName, index)}>X</button>
        </div>
        <div>
            {/* <div>{user.name}</div>
            <div>{user.userName}</div>
            <div>{user.password}</div>
            <div>{user.phoneNumber}</div>
            <div>{user.balance}</div> */}
            <Form onSubmit={handleBlock} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                {/* <label className="label" htmlFor="username">Username</label>
                <div align="left" className="form-control">{userName}</div> */}
                <label className="label" htmlFor="username">{role === "admin" ? "Agent ID" : "User ID"}</label>
                <TextField
                  value={userName}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="userName"
                  name="userName"
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="form-group">
                {/* <label className="label" htmlFor="username">Name</label>
                <div align="left" className="form-control">{name}</div> */}
                <label className="label" htmlFor="username">{role === "admin" ? "Agent Name" : "User Name"}</label>
                <TextField
                  value={name}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="userName"
                  name="userName"
                  autoComplete="off"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              {/* <div className="form-group">
                <label className="label" htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={!successful && [required, validEmail]}
                />
              </div> */}
              <div className="form-group">
                <label className="label" htmlFor="password">Enter Your Password</label>
                <TextField
                  value={password}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  onChange={onChangePassword}
                  name="password"
                  autoFocus
                  autoComplete="off"
                  type="password"
                />
                {/* <Input
                  id="reg_password"
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={!successful && [required, vpassword]}
                /> */}
              </div>          

              <div className="form-group" style={{marginTop: "30px"}}>
                <button className="board-buttons board-buttons-nav-bar-dark-small">{user.accountStatus === "BLOCKED" ? "Unblock" : "Block"}</button>
              </div>
            </div>
          )}

            {/* <div>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className="form-group">
                <button className="btn btn-primary btn-block">Add</button>
              </div>
            </div> */}

          {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
        </div>

      {successful && (<div className="form-group">
        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
          {userName} is {user.accountStatus === "BLOCKED" ? "Unblocked" : "Blocked"} Successfully!
        </div>
      </div>)}
      </div>
    </div>
    )
}