import React from 'react'

const Records = ({ledger}) => {
    const Row = ({ ledger }) => {
		return (
			<>	<div className='report-row'>
					<div style={{paddingTop: "10px",  width: "13%"}}>{ledger.date}</div>
					{ledger.debitmessage ? <div style={{paddingTop: "4px", width: "21%"}}>{ledger.debitmessage}</div> : <div style={{paddingTop: "10px", width: "21%"}}>--</div>}
                    {ledger.creditmessage ? <div style={{paddingTop: "4px", width: "21%"}}>{ledger.creditmessage}</div> : <div style={{paddingTop: "10px", width: "21%"}}>--</div>}
                    {ledger.othermessage ? <div style={{paddingTop: "4px", width: "21%"}}>{ledger.othermessage}</div> : <div style={{paddingTop: "10px", width: "21%"}}>--</div>}
					<div style={{paddingTop: "10px", width: "9%"}}>{ledger.amount}</div>
				</div>
			</>
		)
	}
    
  return (
    <div>
		<div className='report-titles'>
		    <div style={{width: "13%"}}>Date</div>
            <div style={{width: "21%"}}>Debit</div>
            <div style={{width: "21%"}}>Credit</div>
            <div style={{width: "21%"}}>Other</div>
            <div style={{width: "9%"}}>Amount</div>
		</div>
		<div>
		    {ledger && ledger.map((l, index) => <Row ledger={l} key={index}/>)}
		</div>
	</div>
  ) 
}

export default Records 