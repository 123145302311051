import React, { useState, useEffect, useCallback, useRef } from "react";
import { logout, addMyUpi } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import { httpHelpers } from "../services/httpHelpers"
import AddDeleteUpiTable from "./AddDeleteUpiTable"
import { clearMessage, setMessage } from "../actions/message";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import ImageUpload from "./ImageUpload";
import TokenService from "../services/token-service";

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
  };

const AddDeleteUpi = () => {
  const href = window.location.href;
	let role = "admin";
	let type = "Agents";
	if (href.includes("agent")) {
		role = "agent";
		type = "Users";
	}

  const form = useRef();
  const checkBtn = useRef();

  const [upiId, setUpiId] = useState("")
  const [description, setDescription] = useState("")
  const [error, setError] = useState("")
  const [showUpi, setShowUpi] = useState(false)
  const [userName, setUserName] = useState("");
  const [myUpis, setMyUpis] = useState(null)
  const [successful, setSuccessful] = useState(false)
  const [showError, setShowError] = useState(true);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [qrCode, setQRCode] = useState("");

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  let url = "alpha/getUpis";
  if (role === "agent")
    url = "beta/getUpis";
  const api = httpHelpers()

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleAddUpi = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();
    dispatch(addMyUpi(userName, upiId, description, password, qrCode, role))
			.then((data) => {
			console.log("add upi: ", data);
				if (data.status === 401) {
          if (data.data === "Wrong password" || data.data === "invalid payload, image size too big.") {
					  setSuccessful(false);
          } else {
            TokenService.removeUser(role);
            logOut();
            window.location.reload();
            // logOut(role);
          }
				} else if (data.status === 200) {
          // reload(true);
          dispatch(setMessage("UPI Added Successfully!"));
          setSuccessful(true);
          setMyUpis(data.data);          
				} else {
          setSuccessful(false);
        }
			})
			.catch(() => {
				setSuccessful(false);
			});
}

  const onChangeName = (e) => {
    const username = e.target.value;
    setUserName(username);
  };

  const onChangeUpiId = (e) => {
    const upiid = e.target.value;
    setUpiId(upiid);
  };

  const onChangeDescription = (e) => {
    const desc = e.target.value;
    setDescription(desc);
  };

  const reload = (refresh) => {
    if (refresh) {
      // window.location.reload();
    } else {
      getMyUpis();
    }
  };

  const getMyUpis = () => {
		api
			.get(`${url}`)
			.then(res => {
				console.log("adddeleteupi res", res.data)
				setMyUpis(res.data)
			})
			.catch(err => {
				console.log(err.response.status)
				if (err.response.status === 401) {
          TokenService.removeUser(role);
          logOut();
          window.location.reload();
					// logOut(role);
				}
			})
	}

  const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);
  // const [content, setContent] = useState("");
  // console.log("acon", content)

  useEffect(() => {
    // if (!showError) {
    //   dispatch(clearMessage());
    // }
    // setShowError(false);
    dispatch(clearMessage());
    getMyUpis();
  }, []);

  return (
    <>
    <div>
          {message && (
            <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
            {message}
          </div>
          )}
        </div>
			<main style={{display: "flex"}}>
    <div className="col-md-4">
      <div className="card card-container">
        <Form onSubmit={handleAddUpi} ref={form}>
              <div className="form-group">
                <label htmlFor="userName" className="label">Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="userName"
                  value={userName}
                  onChange={onChangeName}
                  validations={[required]}
                />
              </div>
              <div className="form-group">
                <label htmlFor="upiId" className="label">UPI ID</label>
                <Input
                  type="text"
                  className="form-control"
                  name="upiId"
                  value={upiId}
                  onChange={onChangeUpiId}
                  validations={[required]}
                />
              </div>
            <div className="form-group">
            <label htmlFor="description" className="label">Description</label>
            <Input
              type="text"
              className="form-control"
              name="description"
              value={description}
              onChange={onChangeDescription}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password" className="label">Password</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>
          <div className="form-group">
            {/* <label htmlFor="qrcode" className="label">QR Code</label> */}
            <ImageUpload setBase64Image={setQRCode}/>
          </div>
          <div className="form-group" style={{marginTop: "30px"}}>
            <button type="submit" className="board-buttons board-buttons-nav-bar-dark-small" disabled={loading} style={{marginTop: "15px"}}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Add UPI</span>
            </button>
          </div>

          {/* {message && (
            <div className="form-group">
              <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )} */}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
        <div className="myupi-container">
          {/* {showStatement && <CrudUser startDate={startDate} endDate={endDate} userName={userName} />} */}
          <div className="col-md-12">
            <AddDeleteUpiTable upis={myUpis} fetchUpis={reload} />
          </div>
        </div>
			</main>
		</>
  );
};

export default AddDeleteUpi;
