import React, { useState, useRef, useEffect, useCallback } from 'react'
// import MailIcon from '@material-ui/icons/Mail';
import MoneyIcon from '@material-ui/icons/Money';
// import NotificationsNoneTwoToneIcon from '@material-ui/icons/NotificationsNoneTwoTone';
// import NotificationsSharpIcon from '@material-ui/icons/NotificationsSharp';
import Badge from '@material-ui/core/Badge';
import { Navigate, useNavigate } from 'react-router-dom';
import { logout } from "../actions/auth";
import { httpHelpers } from "../services/httpHelpers"
import { useDispatch, useSelector } from "react-redux";

export default function WithdrawIcon() {
    const href = window.location.href;
    let role = "admin";
      let urlPrefix = "alpha";
    if (href.includes("agent")) {
      role = "agent";
          urlPrefix = "beta";
    }
    const [badgeCount, setBadgeCount] = useState(0);
    const [rechargeRequests, setRechargeRequests] = useState(5);
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let timeLeft = 5;

    // We need ref in this, because we are dealing
    // with JS setInterval to keep track of it and
    // stop it when needed
    const Ref = useRef(null);
    const [url, setURL] = useState("beta/withDrawRechargeCount");
    const api = httpHelpers();
  
    // The state for our timer
    // const [timer, setTimer] = useState('00:00:00');

    // const fetchPendingRechargeRequests = (timer) => {
    //     console.log("fetch", timer);
    // }

    const fetchPendingWithdrawRequests = (timer) => {
      // api
      // .get(`${url}`)
      // .then(res => {
      //   setBadgeCount(res.data);
      //   if (Ref.current) {
      //     clearInterval(Ref.current);
      //     // clearTimer(getDeadTime());
      //   }
      //   timeLeft = 5;
      // })
      // .catch(err => {
      //   console.log(err.response.status)
      //   if (err && err.response.status === 401) {
      //     logOut();
      //   }
      // })

      if (window.withdrawCount) {
        setBadgeCount(window.withdrawCount);
      }
    }

    const logOut = useCallback(() => {
    dispatch(logout("Session Expired. Please Login Again!", role));
    if (role === "admin") {
      navigate("/logadmin");
    } else if (role === "agent") {
      navigate("/logagent");
    }
  }, [dispatch]);
  
  const getTimeRemaining = (e) => {
    timeLeft = timeLeft - 1;
    const seconds = timeLeft;
    return seconds;
  }
  
    const startTimer = (e) => {
        let seconds = getTimeRemaining(e);
        console.log("seconds", seconds);
        if (seconds >= 0) {
            // update the timer
            // check if less than 10 then we need to 
            // add '0' at the beginning of the variable
            // setTimer(
            //     (hours > 9 ? hours : '0' + hours) + ':' +
            //     (minutes > 9 ? minutes : '0' + minutes) + ':'
            //     + (seconds > 9 ? seconds : '0' + seconds)
            // )
            // console.log((hours > 9 ? hours : '0' + hours) + ':' +
            // (minutes > 9 ? minutes : '0' + minutes) + ':'
            // + (seconds > 9 ? seconds : '0' + seconds));
            timeLeft = 5;
            fetchPendingWithdrawRequests(seconds);
        }
    }
  
    // const clearTimer = (e) => {
    //     // If you adjust it you should also need to
    //     // adjust the Endtime formula we are about
    //     // to code next    
    //     // setTimer('');
  
    //     // If you try to remove this line the 
    //     // updating of timer Variable will be
    //     // after 1000ms or 1sec
    //     console.log("timeLeft", timeLeft);
    //     if (timeLeft > 0) {
    //       if (Ref.current) {
    //         clearInterval(Ref.current);
    //         Ref.current = null;
    //       }

    //       const id = setInterval (() => {
    //         startTimer(e);
    //       }, 10000)
    //       Ref.current = id;
    //     }
    // }
  
    const getDeadTime = () => {
        let deadline = new Date();
        // This is where you need to adjust if 
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds() + 5);
        return deadline;
    }
  
    // We can use useEffect so that when the component
    // mount the timer will start as soon as possible
  
    // We put empty array to act as componentDid
    // mount only
    useEffect(() => {
      // if (Ref.current) {
      //   clearTimer(getDeadTime());
      // }
      // if (Ref.current) {
      //   clearInterval(Ref.current);
      //   Ref.current = null;
      // }

      const id = setInterval (() => {
        startTimer(seconds => seconds + 5);
      }, 10000)
      return () => clearInterval(id);
    }, []);
  
    // Another way to call the clearTimer() to start
    // the countdown is via action event from the
    // button first we create function to be called
    // by the button
    const onClickReset = () => {
        // clearTimer(getDeadTime());
    }

    const goToWithdrawApproval = () => {
      navigate("/withdrawagent");
    }

    return (
      <div>
        <span>Withdraw</span>
        <Badge badgeContent={badgeCount} color="secondary" overlap="rectangular">
          {/* <MailIcon>
            <button onClick={() => goToRechargeApproval()}></button>
          </MailIcon>
          <NotificationsIcon>
            <button onClick={() => goToRechargeApproval()}></button>
          </NotificationsIcon>
          <NotificationsNoneTwoToneIcon>
            <button onClick={() => goToRechargeApproval()}></button>
          </NotificationsNoneTwoToneIcon> */}
          <MoneyIcon>
            <button onClick={() => goToWithdrawApproval()}></button>
          </MoneyIcon>
        </Badge>
      </div>
    );
}
