import React, { useState, useEffect, useCallback } from "react";
//import UserService from "../services/user-service";
import CrudUser from "./CrudUser"
import MyCalendar from './MyCalendar';
import MyDatePicker from './MyDatePicker';
//import "../styles/Adag.css"
import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import { httpHelpers } from "../services/httpHelpers"
import ReportTable from "./ReportTable"
import TokenService from "../services/token-service";

const ReportAdmin = () => {
  const href = window.location.href;
	let role = "admin";
	let type = "Agents";
	if (href.includes("agent")) {
		role = "agent";
		type = "Users";
	}

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [error, setError] = useState("")
  const [showStatement, setShowStatement] = useState(false)
  const [userName, setUserName] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [ledgers, setLedgers] = useState(null)

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const url = "alpha/getLedgerEntries?startDate=" + startDate 
  + "&endDate=" + endDate 
  + "&agentOrUserName=" + userName
  + "&offset=" + pageNumber
  + "&recordsPerPage=" + recordsPerPage;
	const api = httpHelpers()

  const handleStartDatePicker = (date) => {
    // if (showStatement) {
    //   setShowStatement(false)
    // }
    // if (date < endDate) {
    //   setStartDate(date)
    //   console.log("start", startDate)
    // } else {
    //   setError("End Date must be greater than Start Date")
    // }
    setStartDate(date)
    console.log("start", startDate)
  }

  const handleEndDatePicker = (date) => {
    // if (showStatement) {
    //   setShowStatement(false)
    // }
    // if (date > startDate) {
    //   setEndDate(date)
    //   console.log("end", endDate)
    // } else {
    //   setError("End Date must be greater than Start Date")
    // }
    setEndDate(date)
    console.log("end", endDate)
  }

  const validateTime = () => {
    if (endDate < startDate) {
      return false;
    }
  }

  const handleGetStatement = (isShow) => {
    // REST API
    console.log(isShow)
    setShowStatement(isShow)
    getLedgers();
  }

  const onChangeUserName = (e) => {
    const username = e.target.value;
    setUserName(username);
  };

  const getLedgers = () => {
		api
			.get(`${url}`)
			.then(res => {
				console.log("ledger res", res.data)
				setLedgers(res.data)
			})
			.catch(err => {
				console.log(err.response.status)
				if (err.response.status === 401) {
          TokenService.removeUser(role);
          logOut();
          window.location.reload();
					// logOut(role);
				}
			})
	}

  const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);
  // const [content, setContent] = useState("");
  // console.log("acon", content)

  // useEffect(() => {
  //   UserService.getAdminBoard().then(
  //     (response) => {
  //       setContent(response.data);
  //     },
  //     (error) => {
  //       const _content =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString();

  //       setContent(_content);
  //     }
  //   );
  // }, []);

  return (
    <>
			<main>
        <div className="calendar">
          <span className="label-report black" style={{marginTop: "5px", marginRight: "5px", paddingTop: "8px"}}>Start Date</span>
          <div className="calendar-specs" style={{marginTop: "3px", paddingTop: "5px"}}>
          <MyDatePicker handleStartDatePicker={() => handleStartDatePicker}/>
          </div>
          <span className="label-report black" style={{marginLeft: "5px", marginTop: "5px", marginRight: "5px", paddingTop: "8px"}}>End Date</span>
          <div className="calendar-specs" style={{marginTop: "3px", paddingTop: "5px"}}>
            <MyDatePicker handleEndDatePicker={() => handleEndDatePicker}/>
          </div>
          <span className="label-report black" style={{marginLeft: "5px", marginTop: "5px", marginRight: "5px", paddingTop: "8px"}}>User Name</span>
          <div style={{marginTop: "5px"}}>
              <input
                  id="userName"
                  type="text"
                  className="form-control"
                  name="userName"
                  value={userName}
                  onChange={onChangeUserName}
                />
            </div>
          <div style={{marginLeft: "5px", marginTop: "3px"}}>
            <button className="board-buttons board-buttons-nav-bar-dark" onClick={() => handleGetStatement(true)}>Get Statement</button>
          </div>
        </div>
        <div>
          <span className="label-report red" style={{marginLeft: "5px"}}>{error}</span>
        </div>
        <div style={{marginTop: "20px"}}>
          {/* {showStatement && <CrudUser startDate={startDate} endDate={endDate} userName={userName} />} */}
          {showStatement && <ReportTable ledgers={ledgers} />}
        </div>
			</main>
		</>
  );
};

export default ReportAdmin;
