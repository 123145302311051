// import React, { useState, useEffect } from "react"
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import TokenService from "../services/token-service";
import whatsapp from '../assets/whatsapp_48.png';
import '../App.css';

const Profile = () => {
  const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const { user: currentUser } = useSelector((state) => state.auth);
  const user = TokenService.getUser(role);
  const uid = user.userName;
  let refer = 'https://api.whatsapp.com/send?text=Join here: https://game.play247.link/register?agentCode=' + user.id;
  if (role === "admin") {
    refer = 'https://api.whatsapp.com/send?text=Join here: https://game.play247.link/registerUser?adminCode=' + user.id;
  }
  // const [width, setWidth] = useState(window.innerWidth);

  if (!currentUser) {
    if (role === "admin") {
      return <Navigate to="/logadmin" />;
    } else if (role === "agent") {
      return <Navigate to="/logagent" />;
    } else {
      return <Navigate to="/logagent" />;
    }
  }

  return (
    <div className="container" style={{color: "black"}}>
      <header className="jumbotron">
        <h3>
          {/* <strong>{currentUser.user.username}</strong> Profile */}
          <strong>{uid}</strong> Profile
        </h3>
      </header>
      {/* <p>
        <strong>Token:</strong> {currentUser.jwt && currentUser.jwt.substring(0, 20)} ...{" "}
        {currentUser.jwt && currentUser.jwt.substr(currentUser.jwt && currentUser.jwt.length - 20)}
      </p> */}
      {/* <p>
        <strong>Id:</strong> {currentUser.user.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.user.email}
      </p> */}
      {/* <strong>Authorities:</strong> {"currentUser.user.role"} */}
      <strong>Authorities:</strong> {role === "admin" ? "Admin" : "Agent"}
      <br></br>
      <br></br>
      {role === "agent" && (<div>
          <strong>Share Referral Link: </strong>
          <a href={refer}
            data-action="share/whatsapp/share"
            target="_blank">
            <img className='rcorners2' src={whatsapp}></img>
          </a></div>)}
    </div>
  );
};

export default Profile;
