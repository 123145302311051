import React, { useState, useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { changePassword, logout, changePasswordProfile } from "../actions/auth";
import "../index.css"
import { Navigate, useNavigate } from 'react-router-dom';
import TokenService from "../services/token-service";
import { clearMessage } from "../actions/message";

const required = (value) => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          This field is required!
        </div>
      );
    }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};
const ChangePassword = () => {
  const href = window.location.href;
  let role = "admin";
  if (href.includes("agent")) {
    role = "agent";
  }
  const form = useRef();
  const checkBtn = useRef();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [showError, setShowError] = useState(true);

  const { message } = useSelector(state => state.message);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    // if (!showError) {
		// 	dispatch(clearMessage());
		// }
		// setShowError(false);
    dispatch(clearMessage());
  }, []);

  const onChangeOldPassword = (e) => {
    const password = e.target.value;
    setOldPassword(password);
  };

    const [input, setInput] = useState({
        newPassword: '',
        confirmNewPassword: ''
    });
    
    const [error, setError] = useState({
        newPassword: '',
        confirmNewPassword: ''
    });
    
    const onInputChange = e => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        validateInput(e);
    }
    
    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" };
            
            switch (name) { 
                case "newPassword":
                    if (input.confirmNewPassword && value !== input.confirmNewPassword) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    } else if (input.confirmNewPassword && value === input.confirmNewPassword) {
                        stateObj[name] = "";
                    } else {
                        stateObj[name] = input.confirmNewPassword ? "" : error.confirmNewPassword;
                    }
                    setNewPassword(value)
                    break;
                
                case "confirmNewPassword":
                    if (input.newPassword && value !== input.newPassword) {
                        stateObj[name] = "Password and Confirm Password does not match.";
                    } else if (input.newPassword && value === input.newPassword) {
                        stateObj[name] = "";
                    } else {
                        stateObj[name] = input.newPassword ? "" : error.newPassword;
                    }
                    setConfirmNewPassword(value)
                break;
                    default:
                    break;
                }
            return stateObj;
        });
    }

    const logOut = useCallback((message) => {
      // dispatch(logout(message, role));
      if (role === "admin") {
        navigate("/logadmin");
      } else if (role === "agent") {
        navigate("/logagent");
      }
    }, [dispatch]);

  const handleChangePassword = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
        const user = TokenService.getUser(role);
        if (user && user.accountStatus === "ACTIVE") {
          dispatch(changePasswordProfile(oldPassword, newPassword, role))
            .then((data) => {
              console.log("cp profile data:", data);
              if (data.status === 401) {
                if (data.data === "Wrong password") {
                  setSuccessful(false);
                } else {
                  TokenService.removeUser(role);
                  logOut();
                  window.location.reload();
                  // logOut("Session Expired. Please Login Again!", role);
                }
              } else {
                if (data.status === 200) {
                  // logOut(role);
                  setSuccessful(true);
                } else {
                  setSuccessful(false);
                }
              }
            })
            .catch(() => {
              setSuccessful(false);
            });
        } else {
          const userName = user.userName;
      dispatch(changePassword(oldPassword, newPassword, userName, role))
        .then((data) => {
          console.log("cp data:", data);
          if (data.status === 401) {
            if (data.data === "Wrong password") {
              setSuccessful(false);
            } else {
              TokenService.removeUser(role);
              logOut();
              window.location.reload();
              // logOut("Session Expired. Please Login Again!", role);
            }
          } else {
            if (data.status === 200) {
              // logOut(role);
              setSuccessful(true);
            } else {
              setSuccessful(false);
            }
          }
        })
        .catch(() => {
          setSuccessful(false);
        });
        }
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleChangePassword} ref={form}>
          {!successful && (
            <div>
              <div className="form-group">
                <label className="label" htmlFor="oldPassword">Old Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={onChangeOldPassword}
                  validations={!successful && [required, vpassword]}
                />
              </div>
              <div className="form-group">
                <label className="label" htmlFor="newPassword">New Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="newPassword"
                  value={newPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                  validations={!successful && [required, vpassword]}
                />
                {error.newPassword && <div className="alert alert-danger" role="alert">{error.newPassword}</div>}
              </div>
              <div className="form-group">
                <label className="label" htmlFor="oldPassword">Confirm New Password</label>
                <Input
                  type="password"
                  className="form-control"
                  name="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={onInputChange}
                  onBlur={validateInput}
                  validations={!successful && [required, vpassword]}
                />
                {error.confirmNewPassword && <div className="alert alert-danger" role="alert">{error.confirmNewPassword}</div>}
              </div>
              <div className="form-group" style={{marginTop: "30px"}}>
                <button className="board-buttons board-buttons-nav-bar-dark-small">Submit</button>
              </div>
            </div>
          )}

          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>

      {successful && (<div className="form-group">
        <div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
          Password is Changed Successfully!
        </div>
      </div>)}
      {message && (
        <div className="form-group">
          <div className="alert alert-danger" role="alert">
            {message}
            </div>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;