import WinnerCheck from '../assets/gold-check.gif';

export default function GameCardContent({odds, winner, selected, winnerWidth}) {
    return (
        <div>
            {/* <div style={{margin: "auto", color: "gold", fontSize: "15px"}}>
                {selected && <div>WINNER</div>}
            </div> */}
            <div style={{display: "flex"}}>
                {/* <span style={{color: "black", marginRight: "5px"}}>Winning Margin:</span> */}
                <span style={{fontSize: "15px", color: "white"}}>{odds}</span>
            </div>
            <div style={{display: "flex"}}>
                {/* <span style={{color: "black", marginRight: "5px"}}>Winnder Candidate:</span> */}
                <span style={{fontSize: "15px", color: "white"}}>{winner}</span>
            </div>
            <div style={{margin: "auto"}}>
                {selected && <img src={WinnerCheck} width={winnerWidth} />}
            </div>
            {/* <div style={{display: "flex"}}>
                <span style={{color: "black", marginRight: "5px"}}>Time Left:</span>
                <Timer/>
            </div> */}
        </div>
    );
};