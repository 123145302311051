import React from 'react'

const Pagination = ({ nPages, currentPage, setCurrentPage, getStatementFromServer }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)

    const nextPage = () => {
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }

    const nextStatementPage = () => {
        console.log("next", currentPage);
        if(currentPage !== nPages) {
            setCurrentPage(currentPage + 1);
            getStatementFromServer(currentPage + 1);
        }
    }
    const prevStatementPage = () => {        
        console.log("prev", currentPage);
        if(currentPage !== 1) {
            setCurrentPage(currentPage - 1);
            getStatementFromServer(currentPage - 1);
        }
    }

    const getSelectedPage = (selectedPage) => {
        console.log("1", selectedPage);
        setCurrentPage(selectedPage);
        getStatementFromServer(selectedPage);
    }

    return (
        <nav style={{marginTop: "10px"}}>
            <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={prevStatementPage} 
                        href='#'>
                        
                        Previous
                    </a>
                </li>
                {pageNumbers.map((pgNumber, index) => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                        <a onClick={() => getSelectedPage(pgNumber)}
                            className='page-link'
                            href='#'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))}
                <li className="page-item">
                    <a className="page-link" 
                        onClick={nextStatementPage}
                        href='#'>
                        
                        Next
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination