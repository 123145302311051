import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { login } from "../actions/auth";
import TokenService from "../services/token-service";
import { clearMessage } from "../actions/message";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = (props) => {
  console.log(window.location.href);
  const href = window.location.href;
  let role = "agent";
  if (href.includes("admin")) {
    role = "admin";
  }
  console.log("role", role);
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(true);
  const [cantConnect, setCantConnect] = useState("");

  const { isLoggedIn, user: currentUser } = useSelector(state => state.auth);
  const { message, types } = useSelector(state => state.message);

  const dispatch = useDispatch();
  // const onChangeUsername = (e) => {
  //   const username = e.target.value;
  //   setUsername(username);
  // };

  const onChangeUserName = (e) => {
    const username = e.target.value;
    setUserName(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    setCantConnect("");
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      // dispatch(login(email, password))
      //   .then(() => {
      //     navigate("/profile");
      //     window.location.reload();
      //   })
      //   .catch(() => {
      //     setLoading(false);
      //   });

        dispatch(login(username, password, role))
        .then((data) => {
          console.log("log data", data);
          if (data.status === 401) {
            setLoading(false);
            setCantConnect("Unauthorized. Please check Id and Password.");
          } else if (data && data.jwt === null && data.accountStatus && data.accountStatus.includes("NEW")) {
            // if (role === "admin") {
            //   navigate("/cpadmin");
            // } else 
            if (role === "agent") {
              navigate("/cpagent");
            }
          } else if (data && data.jwt && data.accountStatus && data.accountStatus.includes("ACTIVE")) {
            if (role === "admin") {
              navigate("/profadmin");
            } else if (role === "agent") {
              navigate("/profagent");
            }
          } else {
            setLoading(false);
          }
          //redirect();
          //window.location.reload();
        })
        .catch(() => {
          console.log("incat", "user");
          setCantConnect("Connection Time Out.");
          setLoading(false);
        });
    } else {
      console.log("inel", "user");
      setLoading(false);
    }
  };

  useEffect(() => {
    redirect();
  }, [currentUser]);

  const redirect = () => {
    console.log("islogged", isLoggedIn, currentUser);
    if (!showError) {
      dispatch(clearMessage());
    }
    setShowError(false);
    if (isLoggedIn && currentUser && currentUser.accountStatus === "ACTIVE") {
      if (role === "admin") {
        return <Navigate to="/profadmin"/>;
      } else if (role === "agent") {
        return <Navigate to="/profagent"/>;
      } else {
        return <Navigate to="/profgent"/>;
      }
    } else if (currentUser && currentUser.accountStatus === "NEW") {
      if (role === "admin") {
        return <Navigate to="/cpadmin"/>;
      } else if (role === "agent") {
        return <Navigate to="/cpagent"/>;
      } else {
        return <Navigate to="/cpagent"/>;
      }
    }
  }

  // if (isLoggedIn) {
  //   return <Navigate to="/profile"/>;
  // }

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleLogin} ref={form}>
          {/* <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
            />
          </div> */}
          {/* <div className="form-group">
                <label htmlFor="email" className="label">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div> */}
              <div className="form-group">
                <label htmlFor="username" className="label">User Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  onChange={onChangeUserName}
                  validations={[required]}
                />
              </div>

          <div className="form-group">
            <label htmlFor="password" className="label">Password</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>
          <div className="form-group" style={{marginTop: "30px"}}>
            <button className="board-buttons board-buttons-nav-bar-dark-small" disabled={loading} style={{marginTop: "15px"}}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Login</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className={ types === "CHANGE_PASSWORD_SUCCESS" ? "alert alert-success" : "alert alert-danger" } role="alert">
                {message}
              </div>
            </div>
          )}
          {cantConnect && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {cantConnect}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Login;
