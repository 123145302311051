import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import Records from './Records';
import Pagination from './Pagination';
import "../App.css";
import MyDatePicker from './MyDatePicker';
//import "../styles/Adag.css"
import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import { httpHelpers } from "../services/httpHelpers"
import { clearMessage } from "../actions/message";
import TokenService from "../services/token-service";

function Statement2() {
    const href = window.location.href;
	let role = "admin";
    let urlPrefix = "alpha";
	if (href.includes("agent")) {
		role = "agent";
        urlPrefix = "beta";
	}
    // To hold the actual data
    //const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    const [firstLoad, setFirstLoad] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    //const [currentRecords, setCurrentRecords] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(10);    

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [error, setError] = useState("")
    const [showStatement, setShowStatement] = useState(false)
    const [noRecordFound, setNoRecordFound] = useState(false)
    const [userName, setUserName] = useState("");
    const [ledgers, setLedgers] = useState(null);
    const [currentRecords, setCurrentRecords] = useState(null)
    const [showError, setShowError] = useState(true);

    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    let navigate = useNavigate();

    let url = "";
    const api = httpHelpers()

    const handleStartDatePicker = (date) => {
        // if (showStatement) {
        //   setShowStatement(false)
        // }
        // if (date < endDate) {
        //   setStartDate(date)
        //   console.log("start", startDate)
        // } else {
        //   setError("End Date must be greater than Start Date")
        // }
        setStartDate(date.getTime())
        console.log("start", startDate)
    }

    const handleEndDatePicker = (date) => {
        // if (showStatement) {
        //   setShowStatement(false)
        // }
        // if (date > startDate) {
        //   setEndDate(date)
        //   console.log("end", endDate)
        // } else {
        //   setError("End Date must be greater than Start Date")
        // }
        setEndDate(date.getTime())
        console.log("end", endDate)
    }

    const validateTime = () => {
        if (endDate < startDate) {
            return false;
        }
    }

    const handleGetStatement = () => {
        // REST API
        // console.log(isShow)
        // setShowStatement(isShow)
        setCurrentPage(1);
        getLedgers();
    }

    const onChangeUserName = (e) => {
        const username = e.target.value;
        setUserName(username);
    };

    const getLedgers = () => {
        updateURL();
        console.log(url);
		api
			.get(`${url}`)
			.then(res => {
				console.log("ledger res", res.data)
                if (res.data.length > 0) {
                    setTotalPages(res.data.totalPages);
                    setLedgers(res.data.ledgerEntryDtos);
                    console.log("ledgers", ledgers);
                    if (res.data.totalPages === 0) {
                        setShowStatement(false);
                        setNoRecordFound(true);
                    } else {
                        setNoRecordFound(false);
                        setShowStatement(true);
                    }
                    // const indexOfLastRecord = currentPage * recordsPerPage;
                    // const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
                    // setCurrentRecords(ledgers.slice(indexOfFirstRecord, indexOfLastRecord));
                    // console.log(currentRecords);
                    //const nPages = Math.ceil(ledgers.length / recordsPerPage)
                    // const nPages = Math.ceil(ledgers.length / recordsPerPage)
                    // setTotalPages(nPages);
                    // setCurrentRecords(ledgers.slice(indexOfFirstRecord, indexOfLastRecord));
                    // console.log("records", currentRecords);
                } else {
                    setShowStatement(false);
                    setNoRecordFound(true);
                }
			})
			.catch(err => {
                setShowStatement(false);
				console.log(err.response.status)
				if (err && err.response.status === 401) {
                    TokenService.removeUser(role);
                    logOut();
                    window.location.reload();
					// logOut(role);
				}
			})
	}

    const logOut = useCallback(() => {
		// dispatch(logout("Session Expired. Please Login Again!", role));
		if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

    const getStatementFromServer = (selectedPage) => {
        // console.log("getStatementFromServer", currentPage);
        // axios.get('MOCK_DATA_' + currentPage + '.json')
        //     .then(res => {
        //         // setData(res.data);
        //         setLoading(false);
        //         setTotalPages(10);
        //         // setCurrentPage(page);
        //     }).catch(() => {
        //         alert('There was an error while retrieving the data')
        //     })
        setCurrentPage(selectedPage);
        console.log("call", currentPage, selectedPage);
        // updateURL();
        // getLedgers();
    }

    function updateURL() {
        url = urlPrefix + "/getLedgerEntries?startDate=" + startDate 
        + "&endDate=" + endDate 
        + "&agentOrUserName=" + userName 
        + "&offset=" + currentPage 
        + "&recordsPerPage=" + recordsPerPage;
    }

    useEffect(() => {
        //getStatementFromServer(currentPage);
        // getLedgers();
        // if (!showError) {
        //     dispatch(clearMessage());
        // }
        // setShowError(false);
        dispatch(clearMessage());
        updateURL();
        console.log("ue", currentPage, url);
        // getLedgers();
    }, [])

    useEffect(() => {
        //getStatementFromServer(currentPage);
        // getLedgers();
        updateURL();
        console.log("ue 1", currentPage, url);
        if (!firstLoad) {
            getLedgers();
        } else {
            setFirstLoad(false);
        }        
    }, [currentPage])

    useEffect(() => {
        //getStatementFromServer(currentPage);
        // getLedgers();
        updateURL();
        console.log("ue 2", currentPage, url);
        // getLedgers();
    }, [startDate])

    useEffect(() => {
        //getStatementFromServer(currentPage);
        // getLedgers();
        updateURL();
        console.log("ue 3", currentPage, url);
        // getLedgers();
    }, [endDate])

    useEffect(() => {
        //getStatementFromServer(currentPage);
        // getLedgers();
        updateURL();
        console.log("ue 4", currentPage, url);
        // getLedgers();
    }, [userName])

    return (
        <>
        <main>
        <div className="calendar">
          <span className="label-report black" style={{marginTop: "5px", marginRight: "5px", paddingTop: "8px"}}>Start Date</span>
          <div className="calendar-specs" style={{marginTop: "3px", paddingTop: "5px"}}>
          <MyDatePicker handleStartDatePicker={() => handleStartDatePicker}/>
          </div>
          <span className="label-report black" style={{marginLeft: "5px", marginTop: "5px", marginRight: "5px", paddingTop: "8px"}}>End Date</span>
          <div className="calendar-specs" style={{marginTop: "3px", paddingTop: "5px"}}>
            <MyDatePicker handleEndDatePicker={() => handleEndDatePicker}/>
          </div>
          <span className="label-report black" style={{marginLeft: "5px", marginTop: "5px", marginRight: "5px", paddingTop: "8px"}}>User Name</span>
          <div style={{marginTop: "5px"}}>
              <input
                  id="userName"
                  type="text"
                  className="form-control"
                  name="userName"
                  value={userName}
                  onChange={onChangeUserName}
                />
            </div>
          <div style={{marginLeft: "5px", marginTop: "5px"}}>
            <button className="board-buttons board-buttons-nav-bar-dark" 
            onClick={() => handleGetStatement(true)}>Get Statement</button>
          </div>
        </div>
        <div>
          <span className="label-report red" style={{marginLeft: "5px"}}>{error}</span>
        </div>
        <div style={{marginTop: "20px"}}>
          {/* {showStatement && <CrudUser startDate={startDate} endDate={endDate} userName={userName} />}
          {showStatement && <ReportTable ledgers={ledgers} />} */}
            <div>
                {/* <h2> Statement </h2> */}
                {ledgers && <Records ledger={ledgers}/>}
                {showStatement && <Pagination
                    nPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    getStatementFromServer={getStatementFromServer}
                />}
                {noRecordFound && <h2 style={{color: "black"}}> No Record Found! </h2>}
                {message && <h2 style={{color: "black"}}> {message} </h2>}
            </div>
        </div>
			</main>
        </>
    );
}

export default Statement2;
