import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate  } from 'react-router-dom';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { LOGOUT } from "../actions/types";
import { clearMessage, setMessage } from "../actions/message";
import TokenService from "../services/token-service";
import { httpHelpers } from "../services/httpHelpers";

export default function UserTransactionHistory({ userName }) {
  const href = window.location.href;
  let role = "admin";
	if (href.includes("agent")) {
		role = "agent";
	}

  const { message } = useSelector(state => state.message);
  const [showError, setShowError] = useState(true);
  const [successful, setSuccessful] = useState(false);

  useEffect(() => {
    if (!showError) {
			dispatch(clearMessage());
		}
		setShowError(false);
  }, []);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const logOut = useCallback(() => {
    if (role === "admin") {
			navigate("/logadmin");
		} else if (role === "agent") {
			navigate("/logagent");
		}
	}, [dispatch]);

  // console.log("message 1", message);
	if (message && message === LOGOUT) {
		console.log("message 3", message);
		TokenService.removeUser("agent");
		logOut();
		window.location.reload();
	}

  const api = httpHelpers();
  const [userTransactionHistory, setUserTransactionHistory] = useState(null);

  useEffect(() => {
    fetchUserTransactionHistory();
  }, []);

  const fetchUserTransactionHistory = () => {
	var url = `beta/getTransactionHistory?userName=${userName}`;
	api
		.get(`${url}`)
      	.then(res => {
			console.log("trans hist res", res.data)
			setUserTransactionHistory(res.data);
		})
		// .then((response) => response.data.users)
      	// .then((allUsers) => setUsers(allUsers))
		.catch(err => {
			console.log(err.response.status)
			if (err.response.status === 401) {
				TokenService.removeUser("agent");
				logOut();
				window.location.reload();
				// logOut(role);
			} else {
        		setSuccessful(false);
			}
	});
  }

  const Row = ({ trans, index }) => {
		return (
			<>	
                <div className='trans-history-row'>
					<div style={{paddingTop: "5px"}}>{trans.transactionDate}</div>
					<div style={{paddingTop: "5px"}}>{trans.transactionType}</div>
                    <div style={{paddingTop: "5px"}}>{trans.transId}</div>
                    <div style={{paddingTop: "5px"}}>{trans.amount}</div>
					<div style={{paddingTop: "5px"}}>{trans.rechargeState}</div>
				</div>
			</>
		)
	}

  return (
		<div>
			{message && (
				<div className="form-group">
					<div className={ successful ? "alert alert-success" : "alert alert-danger" } role="alert">
					{message}
					</div>
				</div>
			)}
			<div className='trans-history-titles'>
				<div>Transaction Date</div>
                <div>Transaction Type</div>
                <div>Transaction ID</div>
                <div>Transaction Amount</div>
				<div>Transaction Status</div>
			</div>
            <div className="trans-container">
				{userTransactionHistory && userTransactionHistory.map((t, index) => <Row trans={t} key={index} index={index} />)}
            </div>
		</div>
  );
};