import authHeader from "./data-service"
import api from "./api";
import TokenService from "./token-service";

export const httpHelpers = () => {

	const customFetch = async (url, options = {}) => {
		let role = "agent";
		if (url.includes === "alpha") {
			role = "admin";
		}
		const user = TokenService.getUser(role);
  		//console.log('helper', user.jwt)
		const defaultMethod = "GET"
		const defaultHeaders = {
			"Content-Type": "application/json",
			"accept": "*/*",
			"Access-Control-Allow-Origin":"*",
			"Authorization": 'Bearer ' + user.jwt
		}
		const controller = new AbortController()
		options.signal = controller.signal

		options.method = options.method || defaultMethod
		options.headers = options.headers
			? { ...defaultHeaders, ...options.headers }
			: defaultHeaders

		options.body = JSON.stringify(options.body) || false
		if (!options.body) delete options.body

		setTimeout(() => {
			controller.abort()
		}, 3000)

		try {
			const response = await fetch(url, options)
			//console.log("getAgents", response)
			return await response.json()
		} catch (err) {
			return err
		}
	}

	const get = (url, options = {}) => {
        return api.get(url)
    }

	const post = (url, options) => {
		options.method = "POST"
		return api.post(url, options)
	}

	const put = (url, options) => {
		options.method = "PUT"
		return api.put(url, options)
	}

	const del = (url, options) => {
		options.method = "DELETE"
		return api.del(url, options)
	}

	return {
		get,
		post,
		put,
		del,
	}
}
